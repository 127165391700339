import React, { useState } from "react";
import {
  Col,
  Row,
  Card,
  Container,
  CardTitle,
  Label,
  FormGroup,
  FormFeedback,
  Input,
} from "reactstrap";
import actions from "../../redux/actions";
import { showError, showSuccess } from "../../utilities/alerts/toastAlerts";
import Select from "react-select";
import validateInput from "../../utilities/validations/emptyValidator";
import { useEffect } from "react";

export default function BroadcastView() {
  const [broadcastStateType, setBroadcastStateType] = useState(null);
  const [errorObj, setErrorData] = useState({});
  const [countries, setCountries] = React.useState([]);
  const [states, setStates] = React.useState([]);

  const [state, setState] = React.useState({
    title: "",
    versionCode: "",
    message: "",
    gender: [],
    minAge: 0,
    maxAge: 0,
    location: "",
    disease: "",
  });

  const validateForm = () => {
    let { isValid, errors } = validateInput({
      title: { value: state.title, name: "title" },
      message: { value: state.message, name: "message" },
    });

    if (!isValid) {
      setErrorData(errors);
      return false;
    }
    return true;
  };

  const [broadcastState] = useState([
    {
      label: "Version Update",
      value: 1,
    },
    {
      label: "Message Broadcast",
      value: 2,
    },
    {
      label: "Gender",
      value: 3,
    },
    {
      label: "Age ",
      value: 4,
    },
    {
      label: "Location",
      value: 5,
    },
    {
      label: "Diseases",
      value: 6,
    },
  ]);
  // ======================================================

  const handleCountryChange = (selectedOption) => {};

  // ======================================================
  const [diseaseState] = useState([
    {
      value: 3,
      label: "ARDS",
    },
    {
      value: 4,
      label: "Asbestosis",
    },
    {
      value: 5,
      label: "Asthma",
    },
    {
      value: 6,
      label: "Asthma attack",
    },
    {
      value: 7,
      label: "Bronchiolitis",
    },
    {
      value: 8,
      label: "Bronchitis",
    },
    {
      value: 9,
      label: "Childhood asthma",
    },
    {
      value: 10,
      label: "COPD",
    },
    {
      value: 11,
      label: "Cystic fibrosis",
    },
    {
      value: 12,
      label: "Exercise-induced asthma",
    },
    {
      value: 13,
      label: "Lung cancer",
    },
    {
      value: 14,
      label: "Occupational asthma",
    },
    {
      value: 15,
      label: "Pleurisy",
    },
    {
      value: 16,
      label: "Pneumonia",
    },
    {
      value: 17,
      label: "Pneumonitis",
    },
    {
      value: 18,
      label: "Pulmonary fibrosis",
    },
    {
      value: 19,
      label: "Reduction in Lung function",
    },
    {
      value: 20,
      label: "SARS-CoV-2",
    },
    {
      value: 21,
      label: "Severe acute respiratory syndrome (SARS)",
    },
    {
      value: 22,
      label: "Tuberculosis",
    },
    {
      value: 23,
      label: "Acne",
    },
    {
      value: 24,
      label: "Atopic dermatitis (eczema)",
    },
    {
      value: 25,
      label: "Baby acne",
    },
    {
      value: 26,
      label: "Bedbugs",
    },
    {
      value: 27,
      label: "Bedsores (pressure ulcers)",
    },
    {
      value: 28,
      label: "Bee sting",
    },
    {
      value: 29,
      label: "Burns",
    },
    {
      value: 30,
      label: "Cellulitis",
    },
    {
      value: 31,
      label: "Chagas disease",
    },
    {
      value: 32,
      label: "Cold sore",
    },
    {
      value: 33,
      label: "Cold urticaria",
    },
    {
      value: 34,
      label: "Common warts",
    },
    {
      value: 35,
      label: "Contact dermatitis",
    },
    {
      value: 36,
      label: "Corns and calluses",
    },
    {
      value: 37,
      label: "Dandruff",
    },
    {
      value: 38,
      label: "Dermatitis",
    },
    {
      value: 39,
      label: "Diaper rash",
    },
    {
      value: 40,
      label: "Dry skin",
    },
    {
      value: 41,
      label: "Edema",
    },
    {
      value: 42,
      label: "Frostbite",
    },
    {
      value: 43,
      label: "Heat rash",
    },
    {
      value: 44,
      label: "Hives and angioedema",
    },
    {
      value: 45,
      label: "HPV infection",
    },
    {
      value: 46,
      label: "Itchy skin (pruritus)",
    },
    {
      value: 47,
      label: "Melanoma",
    },
    {
      value: 48,
      label: "Moles",
    },
    {
      value: 49,
      label: "Mosquito bites",
    },
    {
      value: 50,
      label: "Poison ivy rash",
    },
    {
      value: 51,
      label: "Psoriasis",
    },
    {
      value: 52,
      label: "Psoriatic arthritis",
    },
    {
      value: 53,
      label: "Rabies",
    },
    {
      value: 54,
      label: "Ringworm (body)",
    },
    {
      value: 55,
      label: "Scabies",
    },
    {
      value: 56,
      label: "Stretch marks",
    },
    {
      value: 57,
      label: "Sun allergy",
    },
    {
      value: 58,
      label: "Sunburn",
    },
    {
      value: 59,
      label: "Sweating and body odor",
    },
    {
      value: 60,
      label: "Wrinkles",
    },
    {
      value: 61,
      label: "Malaria",
    },
    {
      value: 62,
      label: "Dengue fever",
    },
    {
      value: 63,
      label: "Chikungunya",
    },
    {
      value: 64,
      label: "Acute sinusitis",
    },
    {
      value: 65,
      label: "Alzheimer's disease",
    },
    {
      value: 66,
      label: "Anaplasmosis",
    },
    {
      value: 67,
      label: "Anthrax",
    },
    {
      value: 68,
      label: "Antibiotic-resistant infections",
    },
    {
      value: 69,
      label: "Anxiety disorders",
    },
    {
      value: 70,
      label: "Arthritis",
    },
    {
      value: 71,
      label: "Atypical depression",
    },
    {
      value: 72,
      label: "Babesiosis",
    },
    {
      value: 73,
      label: "Bird flu (Avian influenza)",
    },
    {
      value: 74,
      label: "Nose Bleed",
    },
    {
      value: 75,
      label: "Campylobacter",
    },
    {
      value: 76,
      label: "Cardiogenic shock",
    },
    {
      value: 77,
      label: "Cardiomyopathy",
    },
    {
      value: 78,
      label: "Cholera",
    },
    {
      value: 79,
      label: "Chronic fatigue syndrome",
    },
    {
      value: 80,
      label: "Chronic sinusitis",
    },
    {
      value: 81,
      label: "Common cold",
    },
    {
      value: 82,
      label: "Common cold in babies",
    },
    {
      value: 83,
      label: "Cryptosporvalueiosis",
    },
    {
      value: 84,
      label: "Dementia",
    },
    {
      value: 85,
      label: "Depression",
    },
    {
      value: 86,
      label: "Ebola virus and Marburg virus",
    },
    {
      value: 87,
      label: "Ehrlichiosis",
    },
    {
      value: 88,
      label: "Encephalitis",
    },
    {
      value: 89,
      label: "Frostnip",
    },
    {
      value: 90,
      label: "Fungal diseases (Valley fever, histoplasmosis)",
    },
    {
      value: 91,
      label: "Giardiasis",
    },
    {
      value: 92,
      label: "Hantavirus",
    },
    {
      value: 93,
      label: "Hantavirus (cardio)-pulmonary syndrome",
    },
    {
      value: 94,
      label: "Harmful algal bloom-associated illness",
    },
    {
      value: 95,
      label: "Heart failure",
    },
    {
      value: 96,
      label: "Heat exhaustion",
    },
    {
      value: 97,
      label: "Heat stroke",
    },
    {
      value: 98,
      label: "Hypothermia",
    },
    {
      value: 99,
      label: "Influenza (flu)",
    },
    {
      value: 100,
      label: "Leptospirosis",
    },
    {
      value: 101,
      label: "Lyme Disease",
    },
    {
      value: 102,
      label: "Migraine with aura",
    },
    {
      value: 103,
      label: "Plague",
    },
    {
      value: 104,
      label: "Postpartum depression",
    },
    {
      value: 105,
      label: "Rift Valley fever",
    },
    {
      value: 106,
      label: "Rotavirus",
    },
    {
      value: 107,
      label: "Salmonellosis",
    },
    {
      value: 108,
      label: "Seasonal Affective Disorder (SAD)",
    },
    {
      value: 109,
      label: "Sinus headaches",
    },
    {
      value: 110,
      label: "Skin Cancer",
    },
    {
      value: 111,
      label: "Social anxiety disorder",
    },
    {
      value: 112,
      label: "Sore throat",
    },
    {
      value: 113,
      label: "Spotted Fever Rickettsiosis",
    },
    {
      value: 114,
      label: "Sprains and strains",
    },
    {
      value: 115,
      label: "Teen depression",
    },
    {
      value: 116,
      label: "Traveler's diarrhea",
    },
  ]);
  const handleCheckboxChange = (e, gender) => {
    const isChecked = e.target.checked;

    setState((prevState) => {
      let updatedGender;
      if (Array.isArray(prevState.gender)) {
        updatedGender = isChecked
          ? [...prevState.gender, gender]
          : prevState.gender.filter((g) => g !== gender);
      } else {
        updatedGender = isChecked ? [gender] : [];
      }
      return { ...prevState, gender: updatedGender };
    });
  };

  const get_country = () => {
    actions
      .getCountries()
      .then((res) => {
        setCountries(
          res.data.map((e) => {
            return {
              label: e.name,
              value: e.id,
            };
          })
        );
      })
      .catch((e) => {});
  };

  const get_states = (id) => {
    actions
      .getStates({
        country_id: id,
      })
      .then((res) => {
        console.log(res);
        setStates(
          res.data.map((e) => {
            return {
              label: e.name,
              value: e.id,
            };
          })
        );
      })
      .catch((e) => {});
  };

  useEffect(() => {
    get_country();
  }, []);

  const onSubmit = () => {
    if (validateForm()) {
      let reqData = {};

      switch (broadcastStateType) {
        case 1:
          reqData = {
            title: state.title + " V" + state.versionCode,
            body: state.message,
          };
          break;

        case 2:
          reqData = {
            title: state.title,
            body: state.message,
          };
          break;

        case 3:
          reqData = {
            title: state.title,
            body: state.message,
            gender: state.gender,
          };
          break;

        case 4:
          reqData = {
            title: state.title,
            body: state.message,
            minAge: state.minAge,
            maxAge: state.maxAge,
          };
          break;

        case 5:
          reqData = {
            title: state.title,
            body: state.message,
            location: state.location,
          };
          break;

        case 6:
          reqData = {
            title: state.title,
            body: state.message,
            disease: state.disease,
          };
          break;

        default:
          break;
      }

      actions
        .sendBroadcastMessage(reqData)
        .then((res) => {
          if (res.statusCode === 200) {
            showSuccess("Send Successfully");
          }
        })
        .catch((error) => {
          console.log(error, "the error value");
          showError(error);
        });
    }
  };

  const onChangeText = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    if (errorObj[name]) {
      setErrorData((data) => ({ ...data, [name]: "" }));
    }
    setState({ ...state, [name]: value });
    console.log(state);
  };

  return (
    <Container fluid className="vh-100">
      <Card
        style={{ borderRadius: 10 }}
        className="shadow p-3 pt-4 mb-5 bg-white "
      >
        <Row>
          <Col>
            <CardTitle>Broadcast Message</CardTitle>
          </Col>
        </Row>
        <Row>
          <Col lg="6">
            <FormGroup>
              <label>Select Type</label>
              <Select
                onChange={(data) => {
                  setBroadcastStateType(data.value);
                  setState({
                    title: "",
                    versionCode: "",
                    message: "",
                  });
                  setErrorData({});
                }}
                options={broadcastState}
                isClearable
              />
              <FormFeedback>Error</FormFeedback>
            </FormGroup>
          </Col>
        </Row>

        {broadcastStateType === 1 ? (
          <>
            <Row>
              <Col>
                <FormGroup>
                  <label>Title</label>
                  <Input
                    name={"title"}
                    onChange={onChangeText}
                    value={state.title}
                    invalid={!!errorObj.title}
                    type="text"
                  />
                  {/* <FormFeedback>{errorObj.interval}</FormFeedback> */}
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <label>Version Code</label>
                  <Input
                    name={"versionCode"}
                    type="text"
                    onChange={onChangeText}
                    value={state.versionCode}
                    invalid={!!errorObj.versionCode}
                    // type="number"
                  />
                  {/* <FormFeedback>{errorObj.startTime}</FormFeedback> */}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <label>Message</label>
                  <Input
                    name={"message"}
                    type="textarea"
                    onChange={onChangeText}
                    value={state.message}
                    invalid={!!errorObj.message}
                  />
                  {/* <FormFeedback>{errorObj.startTime}</FormFeedback> */}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <div
                className="create-btn ml-3"
                style={{
                  textDecoration: "none",
                  width: 100,
                  textAlign: "center",
                }}
                onClick={onSubmit}
              >
                Submit
              </div>
            </Row>
          </>
        ) : (
          <></>
        )}
        {broadcastStateType === 2 ? (
          <>
            <Row>
              <Col>
                <FormGroup>
                  <label>Title</label>
                  <Input
                    name={"title"}
                    onChange={onChangeText}
                    value={state.title}
                    invalid={!!errorObj.title}
                    type="text"
                  />
                  {/* <FormFeedback>{errorObj.interval}</FormFeedback> */}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <label>Message</label>
                  <Input
                    name={"message"}
                    type="textarea"
                    onChange={onChangeText}
                    value={state.message}
                    invalid={!!errorObj.message}
                  />
                  {/* <FormFeedback>{errorObj.startTime}</FormFeedback> */}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <div
                className="create-btn ml-3"
                style={{
                  textDecoration: "none",
                  width: 100,
                  textAlign: "center",
                }}
                onClick={onSubmit}
              >
                Submit
              </div>
            </Row>
          </>
        ) : (
          <></>
        )}

        {/* =================================================================================== */}
        {broadcastStateType === 3 ? (
          <>
            <Row>
              <Col>
                <label>Select Gender</label>
                <Col>
                  <Row className="align-items-center">
                    <FormGroup check>
                      <Label check>
                        <Input
                          type="checkbox"
                          onChange={(e) => handleCheckboxChange(e, "male")}
                        />{" "}
                        <h6>Male</h6>
                      </Label>
                    </FormGroup>

                    <FormGroup check className="ml-5">
                      <Label check>
                        <Input
                          type="checkbox"
                          onChange={(e) => handleCheckboxChange(e, "female")}
                        />{" "}
                        <h6>Female</h6>
                      </Label>
                    </FormGroup>
                    <FormGroup check className="ml-5">
                      <Label check>
                        <Input
                          type="checkbox"
                          onChange={(e) => handleCheckboxChange(e, "other")}
                        />{" "}
                        <h6>Other</h6>
                      </Label>
                    </FormGroup>
                  </Row>
                  {/* ================================= */}
                  <Row>
                    <Col>
                      <FormGroup>
                        <label>Title</label>
                        <Input
                          name={"title"}
                          onChange={onChangeText}
                          value={state.title}
                          invalid={!!errorObj.title}
                          type="text"
                        />
                        {/* <FormFeedback>{errorObj.interval}</FormFeedback> */}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <label>Message</label>
                        <Input
                          name={"message"}
                          type="textarea"
                          onChange={onChangeText}
                          value={state.message}
                          invalid={!!errorObj.message}
                        />
                        {/* <FormFeedback>{errorObj.startTime}</FormFeedback> */}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <div
                      className="create-btn ml-3"
                      style={{
                        textDecoration: "none",
                        width: 100,
                        textAlign: "center",
                      }}
                      onClick={onSubmit}
                    >
                      Submit
                    </div>
                  </Row>
                  {/* ============================ */}
                </Col>
              </Col>
            </Row>
          </>
        ) : (
          <></>
        )}
        {/* =========================================================================================== */}
        {broadcastStateType == 4 ? (
          <>
            <Col>
              <Row>
                <Col>
                  <FormGroup>
                    <label>Minimum Age</label>
                    <Input
                      name={"minAge"}
                      onChange={onChangeText}
                      defaultValue={state.minAge}
                      // invalid={!!errorObj.title}
                      type="number"
                    />
                    {/* <FormFeedback>{errorObj.interval}</FormFeedback> */}
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <label>Maximum Age</label>
                    <Input
                      name={"maxAge"}
                      onChange={onChangeText}
                      defaultValue={state.maxAge}
                      // invalid={!!errorObj.title}
                      type="number"
                    />
                    {/* <FormFeedback>{errorObj.interval}</FormFeedback> */}
                  </FormGroup>
                </Col>
              </Row>
              {/* ================================= */}
              <Row>
                <Col>
                  <FormGroup>
                    <label>Title</label>
                    <Input
                      name={"title"}
                      onChange={onChangeText}
                      value={state.title}
                      invalid={!!errorObj.title}
                      type="text"
                    />
                    {/* <FormFeedback>{errorObj.interval}</FormFeedback> */}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <label>Message</label>
                    <Input
                      name={"message"}
                      type="textarea"
                      onChange={onChangeText}
                      value={state.message}
                      invalid={!!errorObj.message}
                    />
                    {/* <FormFeedback>{errorObj.startTime}</FormFeedback> */}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <div
                  className="create-btn ml-3"
                  style={{
                    textDecoration: "none",
                    width: 100,
                    textAlign: "center",
                  }}
                  onClick={onSubmit}
                >
                  Submit
                </div>
              </Row>
              {/* ============================ */}
            </Col>
          </>
        ) : (
          <></>
        )}

        {/* ========================================================================= */}
        {broadcastStateType == 6 ? (
          <>
            <Col>
              <Row>
                <Col lg={6}>
                  <FormGroup>
                    <label>Select Disease</label>
                    <Select options={diseaseState} isClearable />
                    <FormFeedback>Error</FormFeedback>
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col>
                  <FormGroup>
                    <label>Title</label>
                    <Input
                      name={"title"}
                      onChange={onChangeText}
                      value={state.title}
                      invalid={!!errorObj.title}
                      type="text"
                    />
                    {/* <FormFeedback>{errorObj.interval}</FormFeedback> */}
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col>
                  <FormGroup>
                    <label>Message</label>
                    <Input
                      name={"message"}
                      type="textarea"
                      onChange={onChangeText}
                      value={state.message}
                      invalid={!!errorObj.message}
                    />
                    {/* <FormFeedback>{errorObj.startTime}</FormFeedback> */}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <div
                  className="create-btn ml-3"
                  style={{
                    textDecoration: "none",
                    width: 100,
                    textAlign: "center",
                  }}
                  onClick={onSubmit}
                >
                  Submit
                </div>
              </Row>
            </Col>
          </>
        ) : (
          <></>
        )}
        {/* =============================================================== */}
        {broadcastStateType === 5 && (
          <Col>
            <Row>
              <Col lg={6}>
                <FormGroup>
                  <label>Select Country</label>
                  <Select
                    onChange={(data) => {
                      get_states(data.value);
                    }}
                    options={countries}
                    isClearable
                  />
                  <FormFeedback>Error</FormFeedback>
                </FormGroup>
              </Col>

              <Col lg={6}>
                <FormGroup>
                  <label>Select City</label>
                  <Select
                    // onChange={handleStateChange}
                    options={states}
                    isClearable
                  />
                  <FormFeedback>Error</FormFeedback>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <label>Title</label>
                  <Input
                    name={"title"}
                    onChange={onChangeText}
                    value={state.title}
                    invalid={!!errorObj.title}
                    type="text"
                  />
                  {/* <FormFeedback>{errorObj.interval}</FormFeedback> */}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <label>Message</label>
                  <Input
                    name={"message"}
                    type="textarea"
                    onChange={onChangeText}
                    value={state.message}
                    invalid={!!errorObj.message}
                  />
                  {/* <FormFeedback>{errorObj.startTime}</FormFeedback> */}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <div
                className="create-btn ml-3"
                style={{
                  textDecoration: "none",
                  width: 100,
                  textAlign: "center",
                }}
                onClick={onSubmit}
              >
                Submit
              </div>
            </Row>
          </Col>
        )}
        {/* ==================================================== */}
      </Card>
    </Container>
  );
}
