import {
  faSort,
  faSortDown,
  faSortUp,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React, { useState } from "react";
import { CSVLink, CSVDownload } from "react-csv";
import { useSortBy, useTable } from "react-table";
import {
  Card,
  Container,
  Nav,
  Navbar,
  Table,
  UncontrolledTooltip,
} from "reactstrap";
import PaginationContainer from "../../components/PaginationContainer";
import actions from "../../redux/actions";
import { showError } from "../../utilities/alerts/toastAlerts";
export default function UsersList() {
  const [tableData, setTableData] = React.useState([]);
  const [modalVisible, setIsModalVisible] = React.useState(false);
  const [editData, setEditData] = React.useState({});
  const [isEdit, setisEdit] = React.useState(false);
  const [pageCount, setPageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = React.useState("");
  const [totalUsers, setTotalUsers] = React.useState("");
  const [csvData,setCsvData]=React.useState([]);

  const getApiCall = () => {
    actions
      .listCustomer({ pageNo: currentPage })
      .then((res) => {
        // setPageCount(Math.ceil(res.data.count / 10));
        setTableData(res.data.rows);
      })
      .catch((err) => {});
  };
  React.useEffect(() => {
    let timerDelay = !!searchText ? 500 : 0;
    const searchTimeout = setTimeout(() => {
      actions
        .listCustomer({
          pageNo: currentPage,
          ...(!!searchText && {
            pageNo: 1,
            search: searchText,
          }),
        })
        .then((res) => {
          console.log(res);
          setTotalUsers(res.data?.count);
          setPageCount(Math.ceil(res.data.count / 10));
          
          setTableData(res?.data?.results);
        })
        .catch((err) => {});

        actions
        .listCustomer({}).then(res=>{
        
          const csvData=res?.data?.results?.map(val=>{
            return({
              Name:val.name,
              "Phone number":val.phoneNumber,
              Email:val.email,
              "Reg. Date":moment(val.createdAt).format("DD MMM YY")
            })
          });
          setCsvData(csvData);
          
        })

      return () => {
        if (searchTimeout) {
          clearTimeout(searchTimeout);
        }
      };
    }, timerDelay);
  }, [currentPage, searchText]);

  const onSearchTextChange = (e) => {
    setSearchText(e.target.value);
  };

  const data = React.useMemo(() => tableData, [tableData]);

  const columns = React.useMemo(
    () => [
      {
        Header: "User Name",
        accessor: "name", // accessor is the "key" in the data
      },
      {
        Header: "Phone number",
        accessor: "phoneNumber",
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Registered on",
        accessor: "createdAt",
      },
    ],
    []
  );

  const onPageClick = (event) => {
    setCurrentPage(event.selected + 1);

    const newOffset = (event.selected * 10) % 20;
  };

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data }, useSortBy);

  return (
    <Container fluid className="vh-100">
      <Navbar className="mt-2 mb-2" color="light">
        <Nav className="me-auto" navbar></Nav>
        <div className="row align-item-start">
          <div className="mr-4">
            <CSVLink data={csvData}>
              <div className="create-btn" style={{ textDecoration: "none" }}>
                Download csv
              </div>
            </CSVLink>

            {/* <Input onChange={onSearchTextChange} placeholder="search" /> */}
          </div>
          <div>Total customer register = {totalUsers}</div>
        </div>
      </Navbar>
      <div className="mb-3" />
      <Card
        style={{ borderRadius: 10 }}
        className="shadow p-3 pt-4 mb-5 bg-white "
      >
        <Table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                <th className="th-color">Sr No.</th>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className="th-color"
                  >
                    {column.render("Header")}
                    <span className="mr-2 ml-2">
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <FontAwesomeIcon icon={faSortDown} />
                        ) : (
                          <FontAwesomeIcon icon={faSortUp} />
                        )
                      ) : (
                        <FontAwesomeIcon
                          style={{ opacity: 0.3 }}
                          icon={faSort}
                        />
                      )}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row, index) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  <td>{(currentPage - 1) * 10 + index + 1}</td>
                  {row.cells.map((cell, j) => {
                    console.log(cell);
                    console.log(row);
                    return (
                      <td
                        style={{
                          textOverflow: "ellipsis",
                          maxWidth: "0px",
                          // width:j==3?"1500px": undefined,
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          marginLeft: 10,
                        }}
                        id={"UncontrolledTooltipExample" + j + index}
                        className="texta"
                        {...cell.getCellProps()}
                      >
                        {cell.column?.id == "phoneNumber" &&
                          row.original?.countryCode}{" "}
                        {cell.column?.id == "createdAt"
                          ? moment(cell.value).format("DD MMM YY")
                          : cell.render("Cell")}
                        <UncontrolledTooltip
                          placement="right"
                          target={"UncontrolledTooltipExample" + j + index}
                        >
                          {cell.render("Cell")}
                        </UncontrolledTooltip>
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </Table>
        <PaginationContainer onPageClick={onPageClick} pageCount={pageCount} />
      </Card>
    </Container>
  );
}
