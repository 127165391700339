import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import FullScreenLoader from "../../components/Loaders/FullScreenLoader";
import actions from "../../redux/actions";
import { showError } from "../../utilities/alerts/toastAlerts";
import validateInput from "../../utilities/validations/emptyValidator";

const options = [
  { value: "chocolate", label: "Chocolate" },
  { value: "strawberry", label: "Strawberry" },
  { value: "vanilla", label: "Vanilla" },
];

export default function CreateEditSymptoms({
  isEdit,
  data = {},
  modalVisible,
  onAddEditomplete,
  toggleModal,
}) {
  const navigate = useNavigate();

  console.log(data, "the jaojodfsjj");
  const [state, setState] = React.useState({
    name: data.name || "",
    maxAge: data.maxAge || "",
    minAge: data.minAge || "",
    basedOnAge: data.basedOnAge ? true : false,
    keywords: data?.keywords || "",
    
  });

  const [isLoading, setIsLoading] = React.useState(false);

  const [errorObj, setErrorData] = useState({
    name: "",
    symptomsCode: "",
    // type: "",
  });

  const updateState = (data) => setState((state) => ({ ...state, ...data }));

  const onChangeText = (e) => {
    if (errorObj[e.target.name]) {
      setErrorData((data) => ({ ...data, [e.target.name]: "" }));
    }
    updateState({ [e.target.name]: e.target.value });
  };

  const onAddEditCommunity = () => {
    let extraDataToValidate = {};
    if (state.basedOnAge) {
      extraDataToValidate = {
        maxAge: { value: state.maxAge, name: "Max age" },
        minAge: { value: state.minAge, name: "Min age" },
      };
    }
    const { isValid, errors } = validateInput({
      name: { value: state.name, name: "Name" },
      ...extraDataToValidate,
    });
    if (!isValid) {
      setErrorData(errors);
      return;
    } else {
      setIsLoading(true);
      let { maxAge, minAge, ...apiData } = state;
      
      if (state.basedOnAge) {
        apiData.maxAge = maxAge;
        apiData.minAge = minAge;
      }
      if (!!state.keywords) {
        apiData.keywords = state.keywords;
      }
      if (isEdit) {
        apiData.id = data.id;
      }

      actions
        .addEditCommunity(apiData)
        .then((res) => {
          setIsLoading(false);
          onAddEditomplete();
          console.log(res, "the result value");
        })
        .catch((error) => {
          setIsLoading(false);
          showError(error);
        });
    }
  };

  console.log(isEdit, "the is edi");

  return (
    <Modal centered isOpen={modalVisible} toggle={toggleModal}>
      <ModalHeader toggle={toggleModal}>{`${
        isEdit ? "Edit" : "Add"
      } Community`}</ModalHeader>
      <ModalBody>
        <Form>
          {/* <FormGroup>
                <label>Vital Id</label>
                <Input
                  name={"vitalId"}
                  onChange={onChangeText}
                  value={state.vitalId}
                  invalid={!!errorObj.vitalId}
                  type="text"
                />
                <FormFeedback>{errorObj.vitalId}</FormFeedback>
              </FormGroup> */}
          <FormGroup>
            <label>Community Name</label>
            <Input
              name={"name"}
              onChange={onChangeText}
              value={state.name}
              invalid={!!errorObj.name}
              type="text"
            />
            <FormFeedback>{errorObj.vitalName}</FormFeedback>
          </FormGroup>
          <FormGroup>
            <label>Based on age</label>
            <div>
              <div className="form-check form-check-inline">
                <Input
                  className="form-check-input radio-inline"
                  type="radio"
                  name="textInput"
                  id="flexRadioDefault1"
                  defaultChecked
                  checked={state.basedOnAge === true}
                  onClick={() => updateState({ basedOnAge: true })}
                />
                <label className="form-check-label" for="flexRadioDefault1">
                  Yes
                </label>
              </div>
              <div className="form-check form-check-inline">
                <Input
                  className="form-check-input radio-inline"
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault2"
                  checked={state.basedOnAge === false}
                  onClick={() => updateState({ basedOnAge: false })}
                />
                <label className="form-check-label" for="flexRadioDefault2">
                  No
                </label>
              </div>
            </div>
          </FormGroup>
          {state.basedOnAge && (
            <>
              <FormGroup>
                <label>Min age</label>
                <Input
                  name={"minAge"}
                  onChange={onChangeText}
                  value={state.minAge}
                  invalid={!!errorObj.minAge}
                  type="number"
                />
                <FormFeedback>{errorObj.minAge}</FormFeedback>
              </FormGroup>
              <FormGroup>
                <label>Max age</label>
                <Input
                  name={"maxAge"}
                  onChange={onChangeText}
                  value={state.maxAge}
                  invalid={!!errorObj.maxAge}
                  type="number"
                />
                <FormFeedback>{errorObj.maxAge}</FormFeedback>
              </FormGroup>
            </>
          )}
          {/* <FormGroup>
                <label>Type</label>
                <Input
                  name={"type"}
                  onChange={onChangeText}
                  value={state.type}
                  invalid={!!errorObj.type}
                  type="number"
                />
                <FormFeedback>{errorObj.type}</FormFeedback>
              </FormGroup> */}
        </Form>
        <FormGroup>
          <label>Keywords</label>
          <Input
            name={"keywords"}
            onChange={onChangeText}
            value={state.keywords}
            invalid={!!errorObj.keywords}
            type="text"
          />
          <FormFeedback>{errorObj.keywords}</FormFeedback>
        </FormGroup>
        <div
          className="create-btn"
          style={{ textDecoration: "none", width: 100, textAlign: "center" }}
          onClick={onAddEditCommunity}
        >
          Submit
        </div>

        <div style={{ height: 30 }} />

        <FullScreenLoader isLoading={isLoading} />
      </ModalBody>
    </Modal>
  );
}
