export const LOGIN = `Admin/login`;
export const CREATE_USER = `Admin/create/user`;
export const EDIT_USER = `Admin/edit/user`;
export const DELETE_USER = `Admin/delete/user`;
export const GET_USER = `Admin/get/user`;

export const ALL_USERS_LIST = `Admin/list`;

export const ADD_EDIT_VITAL = `Admin/add_edit_vital_data`;
export const LIST_VITAL_DATA = `Admin/list_vital_data`;
export const ADD_EDIT_SYMPTOMS = `Admin/add_edit_symptoms`;
export const LIST_SYMPTOMS = `Admin/list_symptoms`;
export const ADD_EDIT_PROFILE_QUESTIONS = `Admin/add_edit_profile_question`;
export const ADD_EDIT_OPTION_FOR_QUESTIONS = `Admin/add_edit_options_for_question`;
export const LIST_OPTION_FOR_QUESTIONS = `Admin/list_options_for_question`;
export const LIST_PROFILE_QUESTION_DATA = `Admin/list_profile_question_data`;
export const ADD_EDIT_VITAL_DATA = `Admin/add_edit_vital_data`;
export const ADD_EDIT_VITAL_PARAM = `Admin/add_edit_vital_parameters`;
export const ADD_VITAL_QUESTION = `Admin/add_edit_vital_question`;
export const LIST_VITAL_QUESTION = `Admin/list_vital_question`;
export const LIST_VITAL_PARAMETER = `Admin/list_vital_parameters`;
export const CREATE_EDIT_DISEASE_CATEGORY = `Admin/add_edit_disease_category`;
export const CREATE_EDIT_DISEASE = `Admin/add_edit_disease`;
export const LIST_DISEASE_CATEGORY = `Admin/list_disease_category`;
export const LIST_DISEASE = `Admin/list_disease`;
export const CREATE_EDIT_RELATION = `Admin/add_edit_vsd_match`;
export const LIST_RELATION = `Admin/list_vsd_match`;
export const DELETE_BLOCK_UNBLOCK = `Admin/update_status`;
export const DELETE_BLOCK_STATUS_DISEASE = `Admin/update_status_disease`;
export const DELETE_BLOCK_DISEASE_CATEGORY = `Admin/update_status_diseaseCategory`;
export const DELETE_BLOCK_SYMPTOMS = `Admin/update_status_symptoms`;
export const DELETE_BLOCK_VITAL_PARAM = `Admin/update_status_vital_parameters`;
export const DELETE_BLOCK_RELATION = `Admin/update_status_vsd`;
export const LIST_REVIEWS = `Admin/list_review`;
export const ADD_EDIT_CONTENT = `Admin/add_edit_content`;
export const LIST_HTML_CONTENT = `Admin/list_content`;
export const LIST_CUSTOMERS=`Admin/list_customer`
export const ADD_EDIT_COMMUNITY=`Admin/add_edit_community`
export const LIST_COMMUNITY=`Admin/list_community`
export const CREATE_NOTIFICATION_CONTROLS =`Admin/controls/trigger/notification`
export const GET_ALL_CUSTOMERS_LOGS =`Admin/customers/logs`
export const CREATE_BROADCAST_MESSAGE =`customer/send_push_notification`
export const CUSTOMER_CALLBACK_REQUEST =`Admin/customers/callback`
export const GET_WEATHER_FACTOR_LIST =`Admin/get_weather_factor_list`
export const GET_COUNTIES =`Admin/create/countries`
export const GET_STATES =`Admin/create/states/search`
export const CREATE_WEATHER_FACTOR = `Admin/weatherFactor`;