import React, { useState } from "react";
import {
  Container,
  Navbar,
  NavbarBrand,
  Nav,
  NavItem,
  Card,
  Table,
  Input,
  UncontrolledTooltip,
} from "reactstrap";
import { useTable, useSortBy } from "react-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faBriefcase,
  faPaperPlane,
  faQuestion,
  faImage,
  faCopy,
  faUpDown,
  faduioton,
  faSort,
  faSortUp,
  faSortDown,
} from "@fortawesome/free-solid-svg-icons";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import actions from "../../redux/actions";
import UnControlledDropdown from "../../components/UnControlledDropdown";
import PaginationContainer from "../../components/PaginationContainer";
import CreateEditQuestion from "./CreateEditQuestion";

const getQuestionType = (path) => {
  switch (path) {
    case "/question/physical":
      return 1;
    case "/question/social":
      return 2;
    case "/question/surgical":
      return 3;
    case "/question/userHistory":
      return 4;
    case "/question/tabacoo":
      return 5;
    case "/question/alcohal":
      return 6;
    case "/question/memberHistory":
      return 7;
    case "/question/lastThing":
      return 8;
    case "/question/enviorment":
      return 9;
    case "/question/immunity":
      return 10;
  }
};

export default function Questions(props) {
  const location = useLocation();
  const navigate = useNavigate();
  const [pageCount, setPageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  console.log(location, "the location data value");
  const [tableData, setTableData] = React.useState([]);
  const [searchText, setSearchText] = React.useState("");
  const [editData, setEditData] = React.useState({});
  const [isEdit, setisEdit] = React.useState(false);
  const [modalVisible, setIsModalVisible] = React.useState(false);
  React.useEffect(() => {
    const type = getQuestionType(location.pathname);
    let timerDelay = !!searchText ? 500 : 0;
    const searchTimeout = setTimeout(() => {
      actions
        .lisiProfileQuestions({
          type,
          pageNo: currentPage,
          ...(!!searchText && {
            pageNo: 1,
            search: searchText,
          }),
        })
        .then((res) => {
          setPageCount(Math.ceil(res.data.count / 10));
          setTableData(res?.data?.rows || []);
        })
        .catch((err) => {
          console.log(err, "the error");
        });
    }, timerDelay);
    return () => {
      if (searchTimeout) {
        clearTimeout(searchTimeout);
      }
    };
  }, [location, currentPage, searchText]);

  const getApiCall = () => {
    const type = getQuestionType(location.pathname);

    actions
      .lisiProfileQuestions({ type, pageNo: 1 })
      .then((res) => {
        setTableData(res?.data?.rows || []);
      })
      .catch((err) => {
        console.log(err, "the error");
      });
  };

  const onSearchTextChange = (e) => {
    setSearchText(e.target.value);
  };
  const data = React.useMemo(() => tableData, [tableData]);
  console.log(tableData, "the aoijsfoidjoj");
  const columns = React.useMemo(
    () => [
      {
        Header: "Question",
        accessor: "question", // accessor is the "key" in the data
      },
      {
        Header: "Question Type",
        accessor: "questionType",
      },
      {
        Header: "Display No",
        accessor: "sequenceNo",
      },
      {
        Header: "Placeholder",
        accessor: "placeHolder",
      },
      {
        Header: "Value Type",
        accessor: "questionKeyBoardType",
      },
      {
        Header: "Condition Question",
        accessor: "conditionalQuestion",
      },
      {
        Header: "Individual Score",
        accessor: "passingValue",
      },
      {
        Header: "Unit",
        accessor: "unit",
      },
    ],
    []
  );
  const onPageClick = (event) => {
    console.log(event.selected, "the evjoaijsdofjo");
    setCurrentPage(event.selected + 1);

    const newOffset = (event.selected * 10) % 20;
    console.log(newOffset, "thejojaosjdoj ");
  };
  const _onClickDropdown = (data, value) => {
    if (value >= 2) {
      console.log(data, "the row data");
      navigate("/question/options", { state: { data } });
    }
  };

  const _onEdit = (data) => {
    let stateData = {
      type: getQuestionType(location.pathname),
      parentName: location.pathname?.replace("/question/", ""),
      data,
      isEdit: true,
    };
    setEditData(data);
    setIsModalVisible(true);
    setisEdit(true);
  };

  const openModal = () => {
    setisEdit(false);
    setIsModalVisible(true);
  };

  const toggleModal = () => {
    setIsModalVisible(false);
  };

  const onAddEditomplete = () => {
    setIsModalVisible(false);
    getApiCall();
  };
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data }, useSortBy);

  return (
    <Container fluid className="vh-100">
      <Navbar className="mt-2 mb-2" color="light">
        <Nav className="me-auto" navbar></Nav>

        <div className="row align-item-start">
          <div className="mr-4">
            <Input onChange={onSearchTextChange} placeholder="search" />
          </div>
          <div
            className="create-btn"
            style={{ textDecoration: "none" }}
            onClick={openModal}
          >
            Create New
          </div>
        </div>
      </Navbar>
      <div className="mb-3" />
      <Card
        style={{ borderRadius: 10 }}
        className="shadow p-3 pt-4 mb-5 bg-white "
      >
        <Table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup, i) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                <th className="th-color">Sr. No</th>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className="th-color"
                  >
                    {column.render("Header")}
                    <span className="mr-2 ml-2">
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <FontAwesomeIcon icon={faSortDown} />
                        ) : (
                          <FontAwesomeIcon icon={faSortUp} />
                        )
                      ) : (
                        <FontAwesomeIcon
                          style={{ opacity: 0.3 }}
                          icon={faSort}
                        />
                      )}
                    </span>
                  </th>
                ))}
                <th className="th-color">Action</th>
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  <td className="texta">{(currentPage - 1) * 10 + i + 1}</td>
                  {row.cells.map((cell, j) => {
                    const coloumnType = cell?.column?.Header;
                    const cellValue = cell.value;
                    const questionTypeValue =
                      (coloumnType === "Question Type" &&
                        (cellValue == 1 || cellValue == 4
                          ? cellValue == 1
                            ? "Text Input"
                            : "Slider"
                          : `${cellValue == 3 ? "Multi " : ""} Dropdown`)) ||
                      null;
                    const conditionalQuestionType =
                      coloumnType === "Condition Question";
                    console.log(coloumnType, "the roouu value");
                    console.log(cellValue, "the cell vlaue");
                    return (
                      <td
                        className={`texta ${
                          questionTypeValue &&
                          (cellValue == 2 || cellValue == 3)
                            ? "dropdown"
                            : ""
                        }`}
                        onClick={() =>
                          _onClickDropdown(row.original, cellValue)
                        }
                        id={"UncontrolledTooltipExample" + j + i}
                        {...cell.getCellProps()}
                        style={{
                          textOverflow: "ellipsis",
                          maxWidth: "0px",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          marginLeft: 10,
                        }}
                      >
                        {!conditionalQuestionType ? (
                          <>
                            {!!questionTypeValue
                              ? questionTypeValue
                              : cell.render("Cell")}
                          </>
                        ) : cellValue == 2 ? (
                          "Yes"
                        ) : (
                          "No"
                        )}
                        <UncontrolledTooltip
                          placement="right"
                          target={"UncontrolledTooltipExample" + j + i}
                        >
                          {!conditionalQuestionType ? (
                            <>
                              {!!questionTypeValue
                                ? questionTypeValue
                                : cell.render("Cell")}
                            </>
                          ) : cellValue == 2 ? (
                            "Yes"
                          ) : (
                            "No"
                          )}
                        </UncontrolledTooltip>
                      </td>
                    );
                  })}
                  <td>
                    <UnControlledDropdown
                      onEdit={_onEdit}
                      data={row.original || {}}
                      type={"PROFILE_QUESTIONS"}
                      onDeleteComplete={getApiCall}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
        <PaginationContainer onPageClick={onPageClick} pageCount={pageCount} />
      </Card>
      {modalVisible && (
        <CreateEditQuestion
          isEdit={isEdit}
          data={editData}
          modalVisible={modalVisible}
          toggleModal={toggleModal}
          onAddEditomplete={onAddEditomplete}
          type={getQuestionType(location.pathname)}
          parentName={location.pathname?.replace("/question/", "")}
        />
      )}
    </Container>
  );
}
