import React, { useState } from "react";
import { Navigate, useHistory, useNavigate, useParams } from "react-router-dom";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Container,
  FormFeedback,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import actions from "../../redux/actions";
import validateInput from "../../utilities/validations/emptyValidator";
import { useLocation } from "react-router-dom";
import FullScreenLoader from "../../components/Loaders/FullScreenLoader";
import { showError } from "../../utilities/alerts/toastAlerts";
import Select from "react-select";

const questionTypeArray = [
  {
    value: "STRING",
    label: "String",
  },
  {
    value: "NUMBER",
    label: "Number",
  },
  {
    value: "CALENDER",
    label: "Calendar",
  },
];
const getStep = (type) => {
  type = parseInt(type);
  switch (type) {
    case 1:
      return "STEP_1";
    case 2:
      return "STEP_2";
    case 3:
      return "STEP_3";
    case 4:
      return "STEP_4";
    case 5:
      return "STEP_5";
    case 6:
      return "STEP_6";
    case 7:
      return "STEP_7";
    case 8:
      return "STEP_8";
    case 9:
      return "STEP_9";
    case 10:
      return "STEP_10"
  }
};

export default function CreateEditQuestion({
  isEdit,
  data = {},
  type,
  parentName,
  modalVisible,
  onAddEditomplete,
  toggleModal,
}) {
  const [state, setState] = React.useState({
    question: data.question || "",
    placeHolder: data.placeHolder || "",
    sequenceNo: data.sequenceNo || "",
    unit: data?.unit || "",
    questionType: data?.questionType || 1,
    checkBoxText: data?.checkBox || "",
    individualPassing: data.individualPassing==2?true : false,
    passingValue: data.passingValue || "",
    conditionalQuestion: data.conditionalQuestion==2?true : false,
  });

  const [selectedQuestionValueType, setSelectedQuestionValueType] =
    React.useState(
      data.questionType == 2
        ? {
            value: "STRING",
            label: "String",
          }
        : null
    );

  const [isLoading, setIsLoading] = React.useState(false);

  const [errorObj, setErrorData] = useState({
    question: "",
    questionType: "",
    sequenceNo: "",
    placeHolder: "",
    selectedQuestionValueType: "",
  });

  const updateState = (data) => setState((state) => ({ ...state, ...data }));

  const onChangeText = (e) => {
    if (errorObj[e.target.name]) {
      setErrorData((data) => ({ ...data, [e.target.name]: "" }));
    }
    updateState({ [e.target.name]: e.target.value });
  };

  const onCreateQuestion = () => {
    console.log("qyhh quesiton");
    console.log(state, "the state data value");
    let { isValid, errors } = validateInput({
      question: { value: String(state.question || ""), name: "Question" },
      sequenceNo: {
        value: String(state.sequenceNo || ""),
        name: "Display No",
      },
      placeHolder: {
        value: state.placeHolder,
        name: "Placeholder",
      },
    });

    if (!selectedQuestionValueType) {
      isValid = false;
      errors.selectedQuestionValueType = "Select question type";
    }
    if (state.individualPassing && !state.passingValue) {
      isValid = false;
      errors.passingValue = "Please enter passing value";
    }

    console.log(errors, "the errors data value");
    if (!isValid) {
      setErrorData(errors);
      return;
    } else {
      setIsLoading(true);
      const { questionType, unit, checkBoxText,passingValue, ...stateApiData } = state;
      let apiData = {
        ...stateApiData,
        type,
        questionType: parseInt(questionType),
        step: getStep(type),
        placeHolder: state.placeHolder,
        questionKeyBoardType: selectedQuestionValueType?.value,
        conditionalQuestion: state.conditionalQuestion?2:1,
        individualPassing: state.individualPassing?2:1,
      };
      if (state.individualPassing) {
        apiData.passingValue = state.passingValue;
      }
      if (!!checkBoxText) {
        apiData.checkBox = checkBoxText;
      } else {
        apiData.checkBox = "";
      }

      console.log(apiData, "the api data value is as follow");
      if (!!unit) {
        apiData.unit = unit;
      }
      if (isEdit) {
        apiData.id = data.id;
      }
      actions
        .addEditProfileQuestion(apiData)
        .then((res) => {
          setIsLoading(false);
          onAddEditomplete();
          console.log(res, "the result value");
        })
        .catch((error) => {
          console.log(error, "the error value");
          setIsLoading(false);
          showError(error);
        });
    }
  };

  const onSelectDropdown = (questionType) => () => {
    setSelectedQuestionValueType({
      value: "STRING",
      label: "String",
    });
    updateState({ questionType });
  };

  const onQuestionValueTypeChange = (data) => {
    setSelectedQuestionValueType(data);
  };

  const changeType = (e) => {};

  console.log(isEdit, "the is edi");
  return (
    <Modal centered isOpen={modalVisible} toggle={toggleModal}>
      <ModalHeader toggle={toggleModal}>{`${
        isEdit ? "Edit" : "Add"
      } Question`}</ModalHeader>

      <ModalBody>
        <Form>
          {/* <FormGroup>
                <label>Vital Id</label>
                <Input
                  name={"vitalId"}
                  onChange={onChangeText}
                  value={state.vitalId}
                  invalid={!!errorObj.vitalId}
                  type="text"
                />
                <FormFeedback>{errorObj.vitalId}</FormFeedback>
              </FormGroup> */}

          <FormGroup>
            <label>Question</label>
            <Input
              name={"question"}
              onChange={onChangeText}
              value={state.question}
              invalid={!!errorObj.question}
              type="text"
            />
            <FormFeedback>{errorObj.question}</FormFeedback>
          </FormGroup>
          <FormGroup>
            <label>Question Type</label>
            <div className="form-check">
              <Input
                className="form-check-input"
                type="radio"
                name="textInput"
                id="flexRadioDefault1"
                checked={state.questionType == 1}
                onClick={() => updateState({ questionType: 1 })}
              />
              <label className="form-check-label" for="flexRadioDefault1">
                Text Input
              </label>
            </div>
            <div className="form-check">
              <Input
                className="form-check-input"
                type="radio"
                name="flexRadioDefault"
                id="flexRadioDefault2"
                checked={state.questionType == 2}
                onClick={onSelectDropdown(2)}
              />
              <label className="form-check-label" for="flexRadioDefault2">
                Dropdown
              </label>
            </div>
            <div className="form-check">
              <Input
                className="form-check-input"
                type="radio"
                name="flexRadioDefault3"
                id="flexRadioDefault3"
                checked={state.questionType == 3}
                onClick={onSelectDropdown(3)}
              />
              <label className="form-check-label" for="flexRadioDefault3">
                Multi-Select Dropdown
              </label>
            </div>
            <div className="form-check">
              <Input
                className="form-check-input"
                type="radio"
                name="flexRadioDefault3"
                id="flexRadioDefault3"
                checked={state.questionType == 4}
                onClick={onSelectDropdown(4)}
              />
              <label className="form-check-label" for="flexRadioDefault3">
                Slider
              </label>
            </div>
          </FormGroup>
          <FormGroup>
            <label>Unit</label>
            <Input
              name={"unit"}
              onChange={onChangeText}
              value={state.unit}
              invalid={!!errorObj.unit}
            />
            <FormFeedback>{errorObj.unit}</FormFeedback>
          </FormGroup>

          <FormGroup>
            <label>Placeholder</label>
            <Input
              name={"placeHolder"}
              onChange={onChangeText}
              value={state.placeHolder}
              invalid={!!errorObj.placeHolder}
            />
            <FormFeedback>{errorObj.placeHolder}</FormFeedback>
          </FormGroup>

          <FormGroup>
            <label>Checkbox Text</label>
            <Input
              name={"checkBoxText"}
              onChange={onChangeText}
              value={state.checkBoxText}
            />
          </FormGroup>

          <FormGroup>
            <label>Value Type</label>
            <Select
              value={selectedQuestionValueType}
              onChange={onQuestionValueTypeChange}
              options={questionTypeArray}
            />
            <p
              className={`invalid-feedback ${
                !!errorObj.selectedQuestionValueType ? "error-text" : ""
              }`}
              valid={false}
            >
              {errorObj.selectedQuestionValueType}
            </p>
          </FormGroup>
          <FormGroup>
            <label>Conditional Question</label>
            <div>
              <div className="form-check form-check-inline">
                <Input
                  className="form-check-input"
                  type="radio"
                  name="textInputb1"
                  id="flexRadioDefault13"
                  checked={state.conditionalQuestion}
                  onClick={() => updateState({ conditionalQuestion: true })}
                />
                <label className="form-check-label" for="flexRadioDefault13">
                  Yes
                </label>
              </div>
              <div className="form-check form-check-inline">
                <Input
                  className="form-check-input"
                  type="radio"
                  name="flexRadioDefault1"
                  id="flexRadioDefault23"
                  checked={!state.conditionalQuestion}
                  onClick={() => updateState({ conditionalQuestion: false })}
                />
                <label className="form-check-label" for="flexRadioDefault23">
                  No
                </label>
              </div>
            </div>
          </FormGroup>
          <FormGroup>
            <label>Individual Passing</label>
            <div>
              <div className="form-check form-check-inline">
                <Input
                  className="form-check-input"
                  type="radio"
                  name="textInputb"
                  id="flexRadioDefault11"
                  checked={state.individualPassing}
                  onClick={() => updateState({ individualPassing: true })}
                />
                <label className="form-check-label" for="flexRadioDefault1">
                  Yes
                </label>
              </div>
              <div className="form-check form-check-inline">
                <Input
                  className="form-check-input"
                  type="radio"
                  name="flexRadioDefaultb"
                  id="flexRadioDefault21"
                  checked={!state.individualPassing}
                  onClick={() => updateState({ individualPassing: false })}
                />
                <label className="form-check-label" for="flexRadioDefault2">
                  No
                </label>
              </div>
            </div>
          </FormGroup>
          {state.individualPassing && (
            <FormGroup>
              <label>Passing Value</label>
              <Input
                name={"passingValue"}
                onChange={onChangeText}
                value={state.passingValue}
                invalid={!!errorObj.passingValue}
                type="number"
              />
              <FormFeedback>{errorObj.passingValue}</FormFeedback>
            </FormGroup>
          )}
          <FormGroup>
            <label>Display No</label>
            <Input
              name={"sequenceNo"}
              onChange={onChangeText}
              value={state.sequenceNo}
              invalid={!!errorObj.sequenceNo}
              type="number"
            />
            <FormFeedback>{errorObj.sequenceNo}</FormFeedback>
          </FormGroup>
        </Form>
        <div
          className="create-btn"
          style={{ textDecoration: "none", width: 100, textAlign: "center" }}
          onClick={onCreateQuestion}
        >
          {isEdit ? "Update" : `Create`}
        </div>
      </ModalBody>

      <div style={{ height: 30 }} />

      <FullScreenLoader isLoading={isLoading} />
    </Modal>
  );
}
