import React, { useState } from "react";
import {
  FormFeedback,
  FormGroup,
  Input,
  Card,
  Container,
  Row,
  Col
} from "reactstrap";
import actions from "../../redux/actions";
import { showError, showSuccess } from "../../utilities/alerts/toastAlerts";
import validateInput from "../../utilities/validations/emptyValidator";
import is from "is_js";

export default function CreateNotificationControls() {

  const [state, setState] = React.useState({
    interval: "",
    startTime: "",
    tempDelta: "",
    humidityDelta: '',
    pressureDelta: ''
  });

  const [errorObj, setErrorData] = useState({});

  const onChangeText = (e) => {
    const name = e.target.name
    const value = e.target.value

    if (errorObj[name]) {
      setErrorData((data) => ({ ...data, [name]: "" }))
    }
    setState({ ...state, [name]: value})
  };

  const validateForm = () => {
    let { isValid, errors } = validateInput({
      interval: { value: state.interval, name: "Interval" },
      startTime: { value: state.startTime, name: "Start Time" }
    });

    if (!isValid) {
      setErrorData(errors);
      return false
    }

    let { tempDelta, pressureDelta, humidityDelta } = state
    let optionalFields = [tempDelta, pressureDelta, humidityDelta]
    const filledOptionalFields = optionalFields.filter(field => is.existy(field) && field !== '');

    if (filledOptionalFields.length === 0) {
      showError({message: 'Please fill in at least any one of the remaining fields'})
      return false
    }

    return true
  }
  const onSubmit = () => {
    if(validateForm()){
      let apiData = {
        ...state,
        startTime: parseInt(state.startTime.split(':')[0]),
        interval: parseInt(state.interval),
        tempDelta: parseInt(state.tempDelta),
        humidityDelta: parseInt(state.humidityDelta),
        pressureDelta: parseInt(state.pressureDelta)
      };

      actions
        .triggerNotificationControls(apiData)
        .then((res) => {
          if(res.statusCode === 200){
            showSuccess('Successfully Sent Notification to users')
          }
        })
        .catch((error) => {
          console.log(error, "the error value");
          showError(error);
        });
    }
  };

  return (
    <Container fluid className="vh-100">
      <Card
        style={{ borderRadius: 10 }}
        className="shadow p-3 pt-4 mb-5 bg-white "
      >
        <Row >
          <Col>
            <FormGroup>
              <label>Interval</label>
              <Input
                name={"interval"}
                onChange={onChangeText}
                value={state.interval}
                invalid={!!errorObj.interval}
                type="text"
              />
              <FormFeedback>{errorObj.interval}</FormFeedback>
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <label>Start Time</label>
              <Input
                name={"startTime"}
                type="time"
                onChange={onChangeText}
                value={state.startTime}
                invalid={!!errorObj.startTime}
              // type="number"
              />
              <FormFeedback>{errorObj.startTime}</FormFeedback>
            </FormGroup>
          </Col>
        </Row>
        <Row >
          <Col>
            <FormGroup>
              <label>Temperature Delta</label>
              <Input
                name={"tempDelta"}
                type="number"
                onChange={onChangeText}
                value={state.tempDelta}
                invalid={!!errorObj.tempDelta}
              />
              <FormFeedback>{errorObj.tempDelta}</FormFeedback>
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <label>Pressure Delta</label>
              <Input
                name={"pressureDelta"}
                onChange={onChangeText}
                type="number"
                value={state.pressureDelta}
                invalid={!!errorObj.pressureDelta}
              />
              <FormFeedback>{errorObj.pressureDelta}</FormFeedback>
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <label>Humidity Delta</label>
              <Input
                name={"humidityDelta"}
                type="number"
                onChange={onChangeText}
                value={state.humidityDelta}
                invalid={!!errorObj.humidityDelta}
              />
              <FormFeedback>{errorObj.humidityDelta}</FormFeedback>
            </FormGroup>
          </Col>
        </Row>

        <div
          className="create-btn"
          style={{ textDecoration: "none", width: 100, textAlign: "center" }}
          onClick={onSubmit}
        >
          Submit
        </div>
      </Card>
    </Container>
  );
}
