import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import FullScreenLoader from "../../components/Loaders/FullScreenLoader";
import actions from "../../redux/actions";
import { showError } from "../../utilities/alerts/toastAlerts";
import validateInput from "../../utilities/validations/vitals";

const options = [
  { value: "chocolate", label: "Chocolate" },
  { value: "strawberry", label: "Strawberry" },
  { value: "vanilla", label: "Vanilla" },
];

export default function CreateEditSymptoms({
  isEdit,
  data = {},
  modalVisible,
  onAddEditomplete,
  toggleModal,
}) {
  const navigate = useNavigate();

  console.log(data, "the jaojodfsjj");
  const [state, setState] = React.useState({
    symptomsName: data.symptomsName || "",
    symptomsCode: data.symptomsCode || "",
    keywords: data?.keywords || "",
    // type: data.type || "",
  });

  const [isLoading, setIsLoading] = React.useState(false);

  const [errorObj, setErrorData] = useState({
    symptomsName: "",
    symptomsCode: "",
    // type: "",
  });

  const updateState = (data) => setState((state) => ({ ...state, ...data }));

  const onChangeText = (e) => {
    if (errorObj[e.target.name]) {
      setErrorData((data) => ({ ...data, [e.target.name]: "" }));
    }
    updateState({ [e.target.name]: e.target.value });
  };

  const onAddEditSymptom = () => {
    const { isValid, errors } = validateInput(state);
    if (!isValid) {
      setErrorData(errors);
      return;
    } else {
      setIsLoading(true);
      let apiData = { ...state, type: 1 };
      if (!!state.keywords) {
        apiData.keywords = state.keywords;
      }
      if (isEdit) {
        apiData.id = data.id;
      }

      actions
        .addEditSymptom(apiData)
        .then((res) => {
          setIsLoading(false);
          onAddEditomplete();
          console.log(res, "the result value");
        })
        .catch((error) => {
          setIsLoading(false);
          showError(error);
        });
    }
  };

  console.log(isEdit, "the is edi");

  return (
    <Modal centered isOpen={modalVisible} toggle={toggleModal}>
      <ModalHeader toggle={toggleModal}>{`${
        isEdit ? "Edit" : "Add"
      } Symptom`}</ModalHeader>
      <ModalBody>
        <Form>
          {/* <FormGroup>
                <label>Vital Id</label>
                <Input
                  name={"vitalId"}
                  onChange={onChangeText}
                  value={state.vitalId}
                  invalid={!!errorObj.vitalId}
                  type="text"
                />
                <FormFeedback>{errorObj.vitalId}</FormFeedback>
              </FormGroup> */}
          <FormGroup>
            <label>Symptom Name</label>
            <Input
              name={"symptomsName"}
              onChange={onChangeText}
              value={state.symptomsName}
              invalid={!!errorObj.symptomsName}
              type="text"
            />
            <FormFeedback>{errorObj.vitalName}</FormFeedback>
          </FormGroup>
          <FormGroup>
            <label>Symptom Code</label>
            <Input
              name={"symptomsCode"}
              onChange={onChangeText}
              value={state.symptomsCode}
              invalid={!!errorObj.symptomsCode}
              type="text"
            />
            <FormFeedback>{errorObj.vitalCode}</FormFeedback>
          </FormGroup>
          {/* <FormGroup>
                <label>Type</label>
                <Input
                  name={"type"}
                  onChange={onChangeText}
                  value={state.type}
                  invalid={!!errorObj.type}
                  type="number"
                />
                <FormFeedback>{errorObj.type}</FormFeedback>
              </FormGroup> */}
        </Form>
        <FormGroup>
          <label>Keywords</label>
          <Input
            name={"keywords"}
            onChange={onChangeText}
            value={state.keywords}
            invalid={!!errorObj.keywords}
            type="text"
          />
          <FormFeedback>{errorObj.keywords}</FormFeedback>
        </FormGroup>
        <div
          className="create-btn"
          style={{ textDecoration: "none", width: 100, textAlign: "center" }}
          onClick={onAddEditSymptom}
        >
          Submit
        </div>

        <div style={{ height: 30 }} />

        <FullScreenLoader isLoading={isLoading} />
      </ModalBody>
    </Modal>
  );
}
