import React, { useState } from "react";
import {
  faSort,
  faSortDown,
  faSortUp,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLocation, useNavigate } from "react-router-dom";
import { useSortBy, useTable } from "react-table";
import { Card, Container, Nav, Navbar, Table,Input } from "reactstrap";
import UnControlledDropdown from "../../components/UnControlledDropdown";
import actions from "../../redux/actions";
import { showError } from "../../utilities/alerts/toastAlerts";
import validateInput from "../../utilities/validations/emptyValidator";
import PaginationContainer from "../../components/PaginationContainer";
import CreateEditDisease from "./CreateEditUser";

export default function UserManagementList(props) {
  const [tableData, setTableData] = React.useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = React.useState(false);

  const [state, setState] = React.useState({
    optionString: "",
    value: "",
    sequenceNo: "",
  });

  const [searchText, setSearchText] = React.useState("");

  const navigate = useNavigate()
  React.useEffect(() => {
    let timerDelay = !!searchText ? 500 : 0;
    const searchTimeout = setTimeout(() => {
      getApiCall();
    }, timerDelay);

    return () => {
      if (searchTimeout) {
        clearTimeout(searchTimeout);
      }
    };
  }, [searchText,currentPage]);

  React.useEffect(() => {
    actions
      .listUsers({
        pageNo: 1,
      })
      .then((res) => {
        console.log(res, "the result value");
      });
  }, []);

  const onSearchTextChange = (e) => {
    setSearchText(e.target.value);
  };

  const getApiCall = () => {
    actions
      .listUsers({
        pageNo: currentPage,
        ...(!!searchText && {
          pageNo: 1,
          search: searchText,
        }),
      })
      .then((res) => {
        console.log('res',res)
        setTableData(res.data);
        setPageCount(Math.ceil(res.data.count / 10));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onPageClick = (event) => {

    setCurrentPage(event.selected + 1);

  };

  const onDeleteConfirm = (data, isBlockAction) => {
    actions
      .deleteUser({
        status : data.status == 1 ? 2 : 1,
        id: data.id,
      })
      .then((res) => {
        getApiCall();
      })
      .catch((error) => {
        showError(error);
      });
  };
  const data = React.useMemo(() => tableData, [tableData]);
  const [errorObj, setErrorData] = useState({});
  const updateState = (data) => setState((state) => ({ ...state, ...data }));

  const columns = React.useMemo(
    () => [
      {
        Header: "Email",
        accessor: "email", // accessor is the "key" in the data
      },
      {
        Header: "Role",
        accessor: "roleId",
        Cell: ({value}) => {
          return value == 1 ? 'Admin' : 'Operation'
        }
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({value}) => {
          return value == 1 ? 'Active' : 'Inactive'
        }
      },
    ],
    []
  );

  const redirect = () => {
    navigate('create')
  };

  const _onEdit = (data) => {
    navigate('create', { state: { id: data.id }})
  };

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data }, useSortBy);

  return (
    <Container fluid className="vh-100">
      <Navbar className="mt-2 mb-2" color="light">
        <Nav className="me-auto" navbar></Nav>

        <div className="row align-item-start">
          {/* <div className="mr-4">
            <Input onChange={onSearchTextChange} placeholder="search" />
          </div> */}
          <div
            className="create-btn"
            style={{ textDecoration: "none" }}
            onClick={redirect}
          >
            Create User
          </div>
        </div>
      </Navbar>
      <div className="mb-3" />
      <Card
        style={{ borderRadius: 10 }}
        className="shadow p-3 pt-4 mb-5 bg-white "
      >
        <Table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup, i) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                <th className="th-color">Sr. No</th>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className="th-color"
                  >
                    {column.render("Header")}
                    <span className="mr-2 ml-2">
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <FontAwesomeIcon icon={faSortDown} />
                        ) : (
                          <FontAwesomeIcon icon={faSortUp} />
                        )
                      ) : (
                        <FontAwesomeIcon
                          style={{ opacity: 0.3 }}
                          icon={faSort}
                        />
                      )}
                    </span>
                  </th>
                ))}
                <th className="th-color">Action</th>
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <tr
                  className={`${row.original.status == "BLOCK" ? "block" : ""}`}
                  {...row.getRowProps()}
                >
                  <td>{(currentPage - 1) * 10 + i + 1}</td>
                  {row.cells.map((cell) => {
                    return (
                      <td className="texta" {...cell.getCellProps()}>
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                  <td>
                    <UnControlledDropdown
                      onEdit={_onEdit}
                      data={row.original || {}}
                      onDeleteConfirm={onDeleteConfirm}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
        <PaginationContainer onPageClick={onPageClick} pageCount={pageCount} />
      </Card>
    </Container>
  );
}
