import React, { useState } from "react";
import {
  Col,
  FormGroup,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import Select, { components } from "react-select";
import { showError, showSuccess } from "../../utilities/alerts/toastAlerts";
import actions from "../../redux/actions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
export default function AddWeatherFactor({
  modalVisible,
  toggleModal,
  onAddEditComplete,
  isEdit,
  editData = {},
  rowIndex,
}) {
  const [broadcastState, setBroadcastState] = useState([
    { label: "January", value: 1, isComplete: editData.january },
    { label: "February", value: 2, isComplete: editData.february },
    { label: "March", value: 3, isComplete: editData.march },
    { label: "April", value: 4, isComplete: editData.april },
    { label: "May", value: 5, isComplete: editData.may },
    { label: "June", value: 6, isComplete: editData.june },
    { label: "July", value: 7, isComplete: editData.july },
    { label: "August", value: 8, isComplete: editData.august },
    { label: "September", value: 9, isComplete: editData.september },
    { label: "October", value: 10, isComplete: editData.october },
    { label: "November", value: 11, isComplete: editData.november },
    { label: "December", value: 12, isComplete: editData.december },
  ]);

  const [state, setState] = React.useState({
    month: "",
    monthIndex: "",
    min_temp: "",
    max_temp: "",
    min_dew: "",
    max_dew: "",
    min_wind: "",
    max_wind: "",
    min_snow: "",
    max_snow: "",
    min_pressure: "",
    max_pressure: "",
  });

  const onChangeText = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setState({ ...state, [name]: parseFloat(value) });
  };

  const onSubmit = () => {
    let isError = false;
    Object.entries(state).forEach((e) => {
      if (e[1] === "") {
        isError = true;
        showError({ message: `${e[0]} is required!!!` });
      }
    });

    if (isError) {
      return;
    }

    const request = {
      cityId: editData.cityId,
      stateId: editData.stateId,
      month: state.month,
      monthIndex: state.monthIndex,
      temp_min: state.min_temp,
      temp_max: state.max_temp,
      dew_min: state.min_dew,
      dew_max: state.max_dew,
      snow_min: state.min_snow,
      snow_max: state.max_snow,
      wind_min: state.min_wind,
      wind_max: state.max_wind,
      pressure_min: state.min_pressure,
      pressure_max: state.max_pressure,
    };
    console.log(request);

    actions
      .createWeatherFactor(request)
      .then((res) => {
        if (res.statusCode === 200) {
          showSuccess("Send Successfully");
          onAddEditComplete(rowIndex , state.month.toLocaleLowerCase());
        }
      })
      .catch((error) => {
        console.log(error, "the error value");
        showError(error);
      });
  };

  const { Option } = components;
  const IconOption = (props) => (
    <Option {...props}>
      {props.data.label}
      {props.data.isComplete === "true" ? (
        <FontAwesomeIcon
          className="ml-2"
          icon={faCircleCheck}
          style={{ color: "#2aa232" }}
        />
      ) : (
        <></>
      )}
    </Option>
  );

  return (
    <Modal
      className="modal-lg"
      centered
      isOpen={modalVisible}
      toggle={toggleModal}
    >
      <ModalHeader
        toggle={toggleModal}
      >{`${editData.cityName} Weather Factor`}</ModalHeader>
      <ModalBody>
        <Col>
          <Row>
            <Col lg="6">
              <FormGroup>
                <label>Select Month</label>
                <Select
                  onChange={(data) => {
                    setState({
                      ...state,
                      month: data.label,
                      monthIndex: data.value,
                    });
                  }}
                  components={{ Option: IconOption }}
                  options={broadcastState}
                  name="month"
                  isClearable
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col lg="6">
              <FormGroup>
                <label>Min Temp (°C)</label>
                <Input
                  type="number"
                  name="min_temp"
                  value={state.min_temp}
                  onChange={onChangeText}
                />
              </FormGroup>
            </Col>
            <Col lg="6">
              <FormGroup>
                <label>Max Temp (°C)</label>
                <Input
                  type="number"
                  name="max_temp"
                  onChange={onChangeText}
                  value={state.max_temp}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col lg="6">
              <FormGroup>
                <label>Min Dew (°C)</label>
                <Input
                  type="number"
                  name="min_dew"
                  onChange={onChangeText}
                  value={state.min_dew}
                />
              </FormGroup>
            </Col>
            <Col lg="6">
              <FormGroup>
                <label>Max Dew (°C)</label>
                <Input
                  type="number"
                  name="max_dew"
                  onChange={onChangeText}
                  value={state.max_dew}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col lg="6">
              <FormGroup>
                <label>Min Snow (mm)</label>
                <Input
                  type="number"
                  name="min_snow"
                  onChange={onChangeText}
                  value={state.min_snow}
                />
              </FormGroup>
            </Col>
            <Col lg="6">
              <FormGroup>
                <label>Max Snow (mm)</label>
                <Input
                  type="number"
                  name="max_snow"
                  onChange={onChangeText}
                  value={state.max_snow}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col lg="6">
              <FormGroup>
                <label>Min Wind (kmh)</label>
                <Input
                  type="number"
                  name="min_wind"
                  onChange={onChangeText}
                  value={state.min_wind}
                />
              </FormGroup>
            </Col>
            <Col lg="6">
              <FormGroup>
                <label>Max Wind (kmh)</label>
                <Input
                  type="number"
                  name="max_wind"
                  onChange={onChangeText}
                  value={state.max_wind}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col lg="6">
              <FormGroup>
                <label>Min Pressure (mb)</label>
                <Input
                  type="number"
                  name="min_pressure"
                  onChange={onChangeText}
                  value={state.min_pressure}
                />
              </FormGroup>
            </Col>
            <Col lg="6">
              <FormGroup>
                <label>Max Pressure (mb)</label>
                <Input
                  type="number"
                  name="max_pressure"
                  onChange={onChangeText}
                  value={state.max_pressure}
                />
              </FormGroup>
            </Col>
          </Row>
          <div
            className="create-btn"
            onClick={onSubmit}
            style={{ textDecoration: "none", width: 100, textAlign: "center" }}
          >
            Update
          </div>
        </Col>
      </ModalBody>
    </Modal>
  );
}
