import React, { useState } from "react";
import {
  FormFeedback,
  FormGroup,
  Input,
  Col,
  Row,
  Card,
  Container,
  CardTitle
} from "reactstrap";
import { useLocation, useNavigate } from "react-router-dom";
import actions from "../../redux/actions";
import { showError, showSuccess } from "../../utilities/alerts/toastAlerts";
import validateInput from "../../utilities/validations/emptyValidator";
import Select from "react-select";

export default function CreateEditUser({}) {
  const location = useLocation();
  const [isEdit, setIsEdit] = useState(false)
  const [state, setState] = React.useState({
    email: "",
    password: "",
    roleId: null
  });

  const [roleData] = useState([
    {
      label: 'Admin',
      value: 1,
    },
    {
      label: 'Operations',
      value: 2,
    }
  ])
  const [errorObj, setErrorData] = useState({});

  React.useEffect(() => {
    const searchTimeout = setTimeout(() => {
      getUser();
    }, 0);

    return () => {
      if (searchTimeout) {
        clearTimeout(searchTimeout);
      }
    };
  }, []);

  const getUser = () => {
    actions
      .getUser({
          id: location.state.id
      })
      .then((res) => {
        setIsEdit(true)
        setState({
          ...state,
          email: res.data.email,
          id: res.data.id,
          password: res.data.password,
          roleId: roleData.find(rol => rol.value === res.data.roleId)
        })
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onChangeText = (e) => {
    const name = e.target.name
    const value = e.target.value

    if (errorObj[name]) {
      setErrorData((data) => ({ ...data, [name]: "" }))
    }
    setState({ ...state, [name]: value})
  };
  const onParamSelect = (data) => {
    setState({ ...state, "roleId": data})
  };
  const validateForm = () => {
    let { isValid, errors } = validateInput({
      email: { value: state.email, name: "email" },
      password: { value: state.password, name: "password" }
    });

    if (!isValid) {
      setErrorData(errors);
      return false
    }
    return true
  }
  const onSubmit = () => {
    if(validateForm()){
      let apiData = {
        ...state,
        roleId: state.roleId?.value
      };

      if(isEdit){
        apiData["id"] = state.id
        actions
        .editUser(apiData)
        .then((res) => {
          if(res.statusCode === 200){
            showSuccess('User Edited Successfully')
          }
        })
        .catch((error) => {
          console.log(error, "the error value");
          showError(error);
        });
      } else {
      actions
        .addEditUser(apiData)
        .then((res) => {
          if(res.statusCode === 200){
            showSuccess('User Created Successfully')
          }
        })
        .catch((error) => {
          console.log(error, "the error value");
          showError(error);
        });
      }
    }
  };
  return (
    <Container fluid className="vh-100">
      <Card
        style={{ borderRadius: 10 }}
        className="shadow p-3 pt-4 mb-5 bg-white "
      >
        <Row>
          <Col>
            <CardTitle>Create / Edit User</CardTitle>
          </Col>
        </Row>
        <Row >
          <Col lg="6">
            <FormGroup>
              <label>Email Id</label>
              <Input
                name={"email"}
                onChange={onChangeText}
                value={state.email}
                invalid={!!errorObj.email}
                type="text"
              />
              <FormFeedback>{errorObj.email}</FormFeedback>
            </FormGroup>
          </Col>
        </Row>
        <Row >
          <Col lg="6">
            <FormGroup>
              <label>Password</label>
              <Input
                name={"password"}
                onChange={onChangeText}
                value={state.password}
                invalid={!!errorObj.password}
                type="text"
              />
              <FormFeedback>{errorObj.password}</FormFeedback>
            </FormGroup>
          </Col>
        </Row>
        <Row >
          <Col lg="6">
            <FormGroup>
              <label>Select Role</label>
              <Select
                value={state.roleId}
                onChange={onParamSelect}
                options={roleData}
                isClearable
              />
              <FormFeedback>{errorObj.roleId}</FormFeedback>
            </FormGroup>
          </Col>
        </Row>
        <div
          className="create-btn"
          style={{ textDecoration: "none", width: 100, textAlign: "center" }}
          onClick={onSubmit}
        >
          Submit
        </div>
      </Card>
    </Container>
  );
}
