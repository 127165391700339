import React from "react";
import { useSelector } from "react-redux";
import { Outlet, Navigate } from "react-router-dom";
const ProtectedRoutes = (props) => {
  const userData = useSelector((state) => state.auth.userData);
  const isLogin = !!userData.token;

  return isLogin ? <Outlet /> : <Navigate to="/login" />;
};

export default ProtectedRoutes;
