import { loginAPI } from "../../api/auth";
import types from "../types";
import store from "../store";
import {
  addEditRelationAPI,
  listRelationsDataAPI,
  deleteBlockUnBlockAPI,
  deleteBlokcRelationAPI,
  listReviewsAPI,
  addEditHtmlContentAPI,
  listHtmlContentAPI,
  listCustomerAPI,
  addEditCommunityAPI,
  listCommunityAPI,
  triggerNotificationControlsApi,
  getCustomersAllLogsApi,
  getCustomerWiseLogsApi,
  getCallbackRequestApi,
  getWeatherFactorApi,
  getCountriesApi,
  getStateApi,
  createWeatherFactorApi
} from "../../api/common";

const { dispatch } = store;

export function addEditRelation(payload) {
  return addEditRelationAPI(payload);
}

export function listRelationsData(payload) {
  return listRelationsDataAPI(payload);
}

export function deleteBlockUnblock(payload) {
  return deleteBlockUnBlockAPI(payload);
}

export function deleteBlockUnblockRelations(payload) {
  return deleteBlokcRelationAPI(payload);
}

export function listReviews(payload) {
  return listReviewsAPI(payload);
}

export function addEditHtmlContent(data = {}) {
  return addEditHtmlContentAPI(data);
}

export function listHtmlContent(data = {}) {
  return listHtmlContentAPI(data);
}

export function listCustomer(data = {}) {
  return listCustomerAPI(data);
}

export function addEditCommunity(data = {}) {
  return addEditCommunityAPI(data);
}

export function listCommunity(data = {}) {
  return listCommunityAPI(data);
}

export function triggerNotificationControls(data = {}) {
  return triggerNotificationControlsApi(data);
}

export function getCustomersAllLogs() {
  return getCustomersAllLogsApi();
}

export function getCustomerWiseLogs(data = {}) {
  return getCustomerWiseLogsApi(data);
}

export function getCallbackRequest() {
  return getCallbackRequestApi();
}

export function getWeatherFactor(data = {}) {
  return getWeatherFactorApi(data);
}

export function getCountries() {
  return getCountriesApi();
}

export function getStates(data = {}) {
  return getStateApi(data);
}

export function createWeatherFactor(data = {}) {
  return createWeatherFactorApi(data);
}