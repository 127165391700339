import React from "react";
import { Card, Container, Nav, Navbar, Table, Button } from "reactstrap";
import PaginationContainer from "../../components/PaginationContainer";
import { useSortBy, useTable } from "react-table";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLocation, useNavigate } from "react-router-dom";
import {
  faSort,
  faSortDown,
  faSortUp,
  faArrowRight,
} from "@fortawesome/free-solid-svg-icons";
import actions from "../../redux/actions";

export default function CustomerWiseLogsList() {
  const [tableData, setTableData] = React.useState([]);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [pageCount, setPageCount] = React.useState(1);

  const location = useLocation();


  const columns = React.useMemo(
    () => [
      {
        Header: "Id",
        accessor: "CustomerLogsData.id",
      },
      {
        Header: "Email",
        accessor: "CustomerLogsData.email",
      },
      {
        Header: "Phone",
        accessor: "CustomerLogsData.phoneNumber",
      },
      {
        Header: "Last log",
        accessor: "logTime",
        Cell: ({ value }) => {
          return new moment(value).format("DD MMM YY, hh:mm A");
        },
      },
      {
        Header: "Location",
        accessor: "location",
      },
    ],
    []
  );
  const data = React.useMemo(() => tableData, [tableData]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data }, useSortBy);

  const getCustomersAllLogs = () => {
    actions
      .getCustomerWiseLogs({
        id : location.state.id
      })
      .then((res) => {
        setTableData(res.data);
      })
      .catch((e) => {});
  };

  React.useEffect(() => {
    getCustomersAllLogs();
  }, []);
  return (
    <Container fluid className="vh-100">
      <Navbar className="mt-2 mb-2" color="light">

        {/* <div className="row align-item-start">
          <div className="mr-4">
          <Input onChange={onSearchTextChange} placeholder="search" />
        </div>
      
        </div> */}
        <h5>CustomerId: {location.state.id}</h5>
      </Navbar>
      <div className="mb-3" />
      <Card
        style={{ borderRadius: 10 }}
        className="shadow p-3 pt-4 mb-5 bg-white "
      >
        <Table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup, i) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                <th className="th-color">Sr. No</th>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className="th-color"
                  >
                    {column.render("Header")}
                    <span className="mr-2 ml-2">
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <FontAwesomeIcon icon={faSortDown} />
                        ) : (
                          <FontAwesomeIcon icon={faSortUp} />
                        )
                      ) : (
                        <FontAwesomeIcon
                          style={{ opacity: 0.3 }}
                          icon={faSort}
                        />
                      )}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <tr
                  className={`${row.original.status == "BLOCK" ? "block" : ""}`}
                  {...row.getRowProps()}
                >
                  <td>{(currentPage - 1) * 10 + i + 1}</td>
                  {row.cells.map((cell) => {
                    return (
                      <td className="texta" {...cell.getCellProps()}>
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </Table>
        <PaginationContainer onPageClick={() => {}} pageCount={pageCount} />
      </Card>
    </Container>
  );
}
