/* eslint-disable no-sparse-arrays */
import Questions from "../views/Questions/Questions";
import CreateVital from "../views/Vitals/CreateVital";
import Vital from "../views/Vitals/Vitals";
import * as Views from "../views";
import {
  faPersonCircleQuestion,
  faPersonRays,
  faFileMedical,
  faDashboard,
  faDisease,
  faTemperatureHalf,
  faCircleNodes,
  faUsersViewfinder,
} from "@fortawesome/free-solid-svg-icons";

const dashboard = [
  {
    Element: Views.Overview,
    path: "/overview",
    name: "OverView",
    icon: faDashboard,
  },
  ,
  {
    Element: Vital,
    path: "/vital",
    name: "Vitals",
    icon: faTemperatureHalf,
    submenu: [
      {
        path: "",
        Element: Views.VitalParameters,
        name: "Parameter",
      },
      {
        path: "/vital_range",
        Element: Vital,
        name: "Range",
      },
      {
        path: "/vital_question",
        Element: Views.VitalQuestion,
        name: "Questions",
      },
    ],
  },
  {
    Element: CreateVital,
    path: "/vital/create",
    name: "Vitals",
    isHidden: true,
  },
  {
    Element: Questions,
    path: "/question",
    name: "Questions",
    icon: faPersonCircleQuestion,
    submenu: [
      {
        path: "/physical",
        Element: Questions,
        name: "Physical",
      },
      {
        path: "/social",
        Element: Questions,
        name: "Social",
      },
      {
        path: "/surgical",
        Element: Questions,
        name: "Surgical",
      },
      {
        path: "/userHistory",
        Element: Questions,
        name: "User History",
      },
      {
        path: "/memberHistory",
        Element: Questions,
        name: "Member History",
      },
      {
        path: "/tabacoo",
        Element: Questions,
        name: "Tabacoo",
      },
      {
        path: "/alcohal",
        Element: Questions,
        name: "Alcohol",
      },
      {
        path: "/lastThing",
        Element: Questions,
        name: "Psychological",
      },
      {
        path: "/enviorment",
        Element: Questions,
        name: "Enviorment",
      },
      {
        path: "/immunity",
        Element: Questions,
        name: "Immunity",
      },
    ],
  },
  {
    Element: Views.Symptoms,
    path: "/symptoms",
    name: "Symptoms",
    icon: faPersonRays,
  },
  {
    Element: Views.CreateEditSymptoms,
    path: "/symptoms/create",
    name: "Symptoms",
    isHidden: true,
  },
  {
    Element: Views.DiseaseCategory,
    path: "/disease_category",
    name: "Disease Category",
    icon: faFileMedical,
  },
  {
    Element: Views.Disease,
    path: "/diseases",
    name: "Disease",
    icon: faDisease,
  },
  {
    Element: Views.CreateEditQuestion,
    path: "/question/create",
    name: "Question",
    isHidden: true,
  },
  {
    Element: Views.Options,
    path: "/question/options",
    name: "Question",
    isHidden: true,
  },
  {
    Element: Views.Relations,
    path: "/relation",
    name: "Relations",
    icon: faCircleNodes,
  },
  {
    Element: Views.Community,
    path: "/community",
    name: "Community",
    icon: faUsersViewfinder,
  },
  {
    Element: Views.UsersList,
    path: "/usersList",
    name: "Users List",
    icon: faUsersViewfinder,
  },
  {
    Element: Views.Reviews,
    path: "/reviews",
    name: "User Reviews",
    icon: faUsersViewfinder,
  },
  {
    Element: Views.CreateNotificationControls,
    path: "/notification/control",
    name: "Notification Controls",
    icon: faUsersViewfinder,
  },
  {
    Element: Views.TermConditions,
    path: "/terms",
    name: "Terms & Conditions",
    icon: faUsersViewfinder,
  },
  {
    Element: Views.PrivacyPolicy,
    path: "/privacyPolicy",
    name: "Privacy Policy",
    icon: faUsersViewfinder,
  },
  {
    Element: Views.UserManagementList,
    path: "/users",
    name: "User Management",
    icon: faPersonCircleQuestion,
  },
  {
    Element: Views.CreateEditUser,
    path: "/users/create",
    name: "User Management",
    icon: faPersonCircleQuestion,
    isHidden: true,
  },
  {
    Element: Views.BroadcastView,
    path: "/broadcast",
    name: "Broadcast Message",
    icon: faPersonCircleQuestion,
  },
  {
    Element: Views.CustomerLogsList,
    path: "/logs",
    name: "Customers Log",
    icon: faPersonCircleQuestion,
  },
  {
    Element: Views.CustomerWiseLogsList,
    path: "/logs/customer",
    name: "Customers Log",
    icon: faPersonCircleQuestion,
    isHidden: true,
  },
  {
    Element: Views.CallBackRequestList,
    path: "/callback",
    name: "Callback Request",
    icon: faPersonCircleQuestion,
  },
  {
    Element: Views.WeatherMatrixView,
    path: "/matrix",
    name: "Weather Matrix",
    icon: faPersonCircleQuestion,
  },
  {
    Element: Views.WeatherFactorView,
    path: "/factor",
    name: "Weather Factor",
    icon: faPersonCircleQuestion,
  },
];

export default dashboard;
