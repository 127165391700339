import React, { useState } from "react";
import { Button, Card, Container, Input, Navbar, Table } from "reactstrap";

import { Col, Row, FormGroup, FormFeedback } from "reactstrap";
import Select from "react-select";

export default function WeatherMatrixView() {
  const [valueIndex, setValueIndex] = useState(null);
  const [state, setState] = useState(null);
  const data = [
    {
      min: "Min (°C)",
      max: "Max (°C)",
      Default: "Default (°C)",
      variation: "Variation (°C)",
      variationValue: 5,
      minValue: 0,
      maxValue: 0,
      defaultValue: 0,
      description:
        "- Global average surface temperature increase \n- 1.5°C and 2°C above pre-industrial levels (Paris Agreement) \n- Regional temperature changes \n- Heatwaves, cold waves, and extreme temperatures",
    },
    {
      min: "Min (°)",
      max: "Max (°)",
      Default: "Default (°)",
      variation: "Variation (°)",
      variationValue: 10,
      minValue: 0,
      maxValue: 0,
      defaultValue: 0,
      description:
        "- Changes in precipitation patterns \n- Increased frequency and intensity of extreme events \n- Changes in seasonal precipitation distribution",
    },
    {
      min: "Min (ph level)",
      max: "Max (ph level)",
      Default: "Default (ph level)",
      variation: "Variation (ph level)",
      variationValue: 0,
      minValue: 0,
      maxValue: 0,
      defaultValue: 8.05,
      // description:
      //   "- Global mean sea level rise \n- Accelerated melting of polar ice caps and glaciers \n- Thermal expansion of seawater",
      description:
      "-Decrease in pH of ocean water \n-Increased absorption of carbon dioxide by oceans",
    },
    {
      min: "Min (km/hr)",
      max: "Max (km/hr)",
      Default: "Default (km/hr)",
      variation: "Variation (km/hr)",
      variationValue: 10,
      minValue: 0,
      maxValue: 0,
      defaultValue: 0,
      // description:
      //   "- Reduction in glacier size and volume \n- Rate of glacial melt",
      description:
      "-Increased frequency and intensity of hurricanes, droughts, floods, wildfires, and storms",
    },
    {
      min: "Min (p/m)",
      max: "Max (p/m)",
      Default: "Default (p/m)",
      variation: "Variation (p/m)",
      variationValue: 20,
      minValue: 0,
      maxValue: 0,
      defaultValue: 421,
      // description:
      //   "-Decrease in pH of ocean water \n-Increased absorption of carbon dioxide by oceans",
      description:
      "-Atmospheric CO2 concentrations \n-400 parts per million (ppm) as a notable threshold",
    },
    {
      min: "Min (ppb)",
      max: "Max (ppb)",
      Default: "Default (ppb)",
      variation: "Variation (% ppb)",
      variationValue: 1,
      minValue: 0,
      maxValue: 0,
      defaultValue: 1911,
      // description:
      //   "-Changes in extent and thickness of ice caps and ice sheets",
      description:
      "-Atmospheric CH4 concentrations \n-Methane is a potent greenhouse gas",
    },
    {
      min: "Min (ppb)",
      max: "Max (ppb)",
      Default: "Default (ppb)",
      variation: "Variation (% ppb)",
      variationValue: 1,
      minValue: 0,
      maxValue: 0,
      defaultValue: 334,
      // description:
      //   "-Increased frequency and intensity of hurricanes, droughts, floods, wildfires, and storms",
      description:
      "-Atmospheric N2O concentrations \n- Nitrous oxide is a greenhouse gas contributing to warming",
    },
    {
      min: "Min (in)",
      max: "Max (in)",
      Default: "Default (in)",
      variation: "Variation (in)",
      variationValue: 7,
      minValue: 0,
      maxValue: 0,
      defaultValue: 0,
      description: "",
      // description:
      //   "-Shifts in species distribution and ecosystems \n-Changes in migration patterns",
    },
    {
      min: "Min (°C)",
      max: "Max (°C)",
      Default: "Default (°C)",
      variation: "Variation (°C)",
      variationValue: 45,
      minValue: 0,
      maxValue: 0,
      defaultValue: 0,
      description: "",
      // description:
      //   "-Atmospheric CO2 concentrations \n-400 parts per million (ppm) as a notable threshold",
    },
    {
      min: "Min (mm)",
      max: "Max (mm)",
      Default: "Default (mm)",
      variation: "Variation (mm)",
      variationValue: 12,
      minValue: 0,
      maxValue: 0,
      defaultValue: 0,
      description: "",
      // description:
      //   "-Atmospheric CH4 concentrations \n-Methane is a potent greenhouse gas",
    },
    {
      min: "Min (µg/m3)",
      max: "Max (µg/m3)",
      Default: "Default (µg/m3)",
      variation: "Variation (µg/m3)",
      variationValue: 50,
      minValue: 0,
      maxValue: 0,
      defaultValue: 0,
      description: "",
      // description:
      //   "-Atmospheric N2O concentrations \n- Nitrous oxide is a greenhouse gas contributing to warming",
    },
    {
      min: "Min (µg/m3)",
      max: "Max (µg/m3)",
      Default: "Default (µg/m3)",
      variation: "Variation (µg/m3)",
      variationValue: 53,
      minValue: 0,
      maxValue: 0,
      defaultValue: 0,
      description: "",
    },
    {
      min: "Min (ppb)",
      max: "Max (ppb)",
      Default: "Default (ppb)",
      variation: "Variation (ppb)",
      variationValue: 50,
      minValue: 0,
      maxValue: 0,
      defaultValue: 0,
      description: "",
    },
  ];

  const onChangeText = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setState({ ...state, [name]: value });
  };
  return (
    <Container>
      <Navbar className="mt-2 mb-2" color="light">
        {/* <div className="row align-item-start">
          <div className="mr-4">
          <Input onChange={onSearchTextChange} placeholder="search" />
        </div>
      
        </div> */}
      </Navbar>
      <div className="mb-3" />
      <Card
        style={{ borderRadius: 10 }}
        className="shadow p-3 pt-4 mb-5 bg-white "
      >
        <Row>
          <Col lg="6">
            <FormGroup>
              <label>Select Factors</label>
              <Select
                onChange={(a) => {
                  setState(data[a.value - 1]);
                }}
                options={[
                  {
                    label: "Temperature",
                    value: 1,
                  },
                  {
                    label: "Precipitation",
                    value: 2,
                  },
                  {
                    label: "Ocean",
                    value: 3,
                  },
                  {
                    label: "Wind",
                    value: 4,
                  },
                  {
                    label: "Co2",
                    value: 5,
                  },
                  {
                    label: "Methane",
                    value: 6,
                  },
                  {
                    label: "N2O",
                    value: 7,
                  },
                  {
                    label: "Snow",
                    value: 8,
                  },
                  {
                    label: "Extreme heat/ Heatwave",
                    value: 9,
                  },
                  {
                    label: "PM2.5",
                    value: 10,
                  },
                  {
                    label: "PM10",
                    value: 11,
                  },
                  {
                    label: "So2",
                    value: 12,
                  },
                  {
                    label: "O3",
                    value: 13,
                  },
                ]}
                isClearable
              />
              <FormFeedback>Error</FormFeedback>
            </FormGroup>
          </Col>
        </Row>

        {state == null ? (
          <></>
        ) : (
          <Col>
            <Row>
              <Col lg="6">
                <FormGroup>
                  <label>{state.Default}</label>
                  <Input
                    type="number"
                    name="defaultValue"
                    value={state.defaultValue}
                    onChange={onChangeText}
                  />
                </FormGroup>
              </Col>
              <Col lg="6">
                {" "}
                <FormGroup>
                  <label>{state.variation}</label>
                  <Input
                    type="number"
                    name="variationValue"
                    value={state.variationValue}
                    onChange={onChangeText}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col lg="6">
                <FormGroup>
                  <label>{state.min}</label>
                  <Input
                    type="number"
                    name="minValue"
                    value={state.minValue}
                    onChange={onChangeText}
                  />
                </FormGroup>
              </Col>
              <Col lg="6">
                <FormGroup>
                  <label>{state.max}</label>
                  <Input
                    type="number"
                    name="maxValue"
                    value={state.maxValue}
                    onChange={onChangeText}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col lg="12">
                <FormGroup>
                  <label>Description</label>
                  <Input
                    className="text-area-custom"
                    type="textarea"
                    name="description"
                    value={state.description}
                    onChange={onChangeText}
                    row
                  />
                </FormGroup>
              </Col>
            </Row>
            <h6 className="mt-3">Notification send</h6>
            <Row>
              <Col lg="2">
                <FormGroup check>
                  <label check>
                    <Input type="radio" name="radio1" /> Yes
                  </label>
                </FormGroup>
              </Col>
              <Col lg="2">
                <FormGroup check>
                  <label check>
                    <Input type="radio" name="radio1" /> No
                  </label>
                </FormGroup>
              </Col>
            </Row>

            <h6 className="mt-3">Notification send to</h6>
            <Row>
              <Col lg="2">
                <FormGroup check>
                  <label check>
                    <Input type="radio" name="radio2" /> Location
                  </label>
                </FormGroup>
              </Col>
              <Col lg="2">
                <FormGroup check>
                  <label check>
                    <Input type="radio" name="radio2" /> Global
                  </label>
                </FormGroup>
              </Col>
            </Row>

            <div
              className="create-btn mt-3"
              // onClick={onSubmit}
              style={{
                textDecoration: "none",
                width: 100,
                textAlign: "center",
              }}
            >
              Update
            </div>
          </Col>
        )}
      </Card>
    </Container>
  );
}
