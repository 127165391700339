import types from "../types";
import store from "../store";
import {
  createUserApi,
  listUsersApi,
  editUserApi,
  deleteUserApi,
  getOneUser
} from "../../api/auth";

const { dispatch } = store;

export function addEditUser(payload) {
  return createUserApi(payload);
}

export function listUsers(payload) {
  return listUsersApi(payload);
}

export function editUser(payload) {
	return editUserApi(payload);
}

export function deleteUser(payload) {
	return deleteUserApi(payload);
}

export function getUser(payload) {
	return getOneUser(payload);
}