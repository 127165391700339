import {
  faSort,
  faSortDown,
  faSortUp,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { useSortBy, useTable } from "react-table";
import { Card, Container, Table, UncontrolledTooltip } from "reactstrap";
import actions from "../../redux/actions";
import { showError } from "../../utilities/alerts/toastAlerts";

export default function Reviews(props) {
  const location = useLocation();
  const [tableData, setTableData] = React.useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [modalVisible, setIsModalVisible] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [state, setState] = React.useState({
    optionString: "",
    value: "",
    sequenceNo: "",
  });
  const [editData, setEditData] = React.useState({});
  const [isEdit, setisEdit] = React.useState(false);
  const [diseaseCategoryData, setDiseaseCategoryData] = React.useState([]);

  const [searchText, setSearchText] = React.useState("");

  React.useEffect(() => {
    let timerDelay = !!searchText ? 500 : 0;
    const searchTimeout = setTimeout(() => {
      getApiCall();
    }, timerDelay);

    return () => {
      if (searchTimeout) {
        clearTimeout(searchTimeout);
      }
    };
  }, [searchText, currentPage]);

  const getApiCall = () => {
    actions
      .listReviews({
        pageNo: 1,
      })
      .then((res) => {
        setTableData(
          res.data?.map((review) => ({
            ...review,
            name: review[`feedbackUserId.name`],
            phoneNumber: review[`feedbackUserId.phoneNumber`],
          }))
        );
      });
  };

  const onPageClick = (event) => {
    setCurrentPage(event.selected + 1);
  };

  const onDeleteConfirm = (data, isBlockAction) => {
    let status = "DELETE";
    if (isBlockAction) {
      status = data.status == "BLOCK" ? "UNBLOCK" : "BLOCK";
    }
    actions
      .deleteDisease({
        status,
        id: data.id,
      })
      .then((res) => {
        getApiCall();
      })
      .catch((error) => {
        showError(error);
      });
  };
  const data = React.useMemo(() => tableData, [tableData]);
  const [errorObj, setErrorData] = useState({});
  const updateState = (data) => setState((state) => ({ ...state, ...data }));
  const columns = React.useMemo(
    () => [
      {
        Header: "Name",
        accessor: `name`, // accessor is the "key" in the data
      },
      {
        Header: "Phone number",
        accessor: "phoneNumber",
      },
      {
        Header: "Comment",
        accessor: "comment",
      },
    ],
    []
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data }, useSortBy);

  return (
    <Container fluid className="vh-100">
      <div className="mb-3" />
      <Card
        style={{ borderRadius: 10 }}
        className="shadow p-3 pt-4 mb-5 bg-white "
      >
        <Table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup, i) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                <th className="th-color">Sr. No</th>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className="th-color"
                  >
                    {column.render("Header")}
                    <span className="mr-2 ml-2">
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <FontAwesomeIcon icon={faSortDown} />
                        ) : (
                          <FontAwesomeIcon icon={faSortUp} />
                        )
                      ) : (
                        <FontAwesomeIcon
                          style={{ opacity: 0.3 }}
                          icon={faSort}
                        />
                      )}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <tr
                  className={`${row.original.status == "BLOCK" ? "block" : ""}`}
                  {...row.getRowProps()}
                >
                  <td>{(currentPage - 1) * 10 + i + 1}</td>
                  {row.cells.map((cell, j) => {
                    return (
                      <td
                        id={"UncontrolledTooltipExample" + j + i}
                        className="texta"
                        {...cell.getCellProps()}
                        style={{
                          textOverflow: "ellipsis",
                          maxWidth: "0px",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          marginLeft: 10,
                        }}
                      >
                        {cell.render("Cell")}
                        {cell.column?.Header == "Comment" && (
                          <UncontrolledTooltip
                            placement="right"
                            target={"UncontrolledTooltipExample" + j + i}
                          >
                            {cell.render("Cell")}
                          </UncontrolledTooltip>
                        )}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </Table>
        {/* <PaginationContainer onPageClick={onPageClick} pageCount={pageCount} /> */}
      </Card>
    </Container>
  );
}
