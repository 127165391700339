import React from "react";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import {
  faHome,
  faBriefcase,
  faPaperPlane,
  faQuestion,
  faImage,
  faDroplet,
  faCopy,
  faDotCircle,
  faEllipsisV,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import ConfirmationAlertModal from "./ConfirmationAlertModal";
import actions from "../redux/actions";
import { showError } from "../utilities/alerts/toastAlerts";
export default function UnControlledDropdown({
  data,
  onEdit,
  onDeleteComplete,
  onDeleteConfirm,
  type,
}) {
  const navigate = useNavigate();
  const [deleteAlert, setDeleteAlert] = React.useState(false);
  const onDelete = ({ isBlockAction = false }) => {
    let status = "DELETE";
    if (isBlockAction) {
      status = data.status == "BLOCK" ? "UNBLOCK" : "BLOCK";
    }

    if (!!onDeleteConfirm) {
      setDeleteAlert(false);
      onDeleteConfirm(data, isBlockAction);
      return;
    }
    if (!!type) {
      actions
        .deleteBlockUnblock({
          status,
          type,
          id: data.id,
        })
        .then((res) => {
          setDeleteAlert(false);
          if (onDeleteComplete) {
            onDeleteComplete();
          }
        })
        .catch((error) => {
          showError(error);
          setDeleteAlert(false);
          console.log(error);
        });
    }
    setDeleteAlert(false);
  };

  const _onEdit = () => {
    if (onEdit) {
      onEdit(data);
      return;
    }
    navigate("create", { state: { isEdit: true, data } });
  };
  return (
    <UncontrolledDropdown>
      <DropdownToggle>
        <FontAwesomeIcon icon={faEllipsisV} className="" />
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem onClick={_onEdit}>Edit</DropdownItem>
        <DropdownItem divider />
        <DropdownItem
          style={{ textTransform: "capitalize" }}
          onClick={() => onDelete({ isBlockAction: true })}
        >
          {data.status == "BLOCK" ? "UNBLOCK" : "BLOCK"}
        </DropdownItem>
        <DropdownItem divider />
        <DropdownItem onClick={() => setDeleteAlert(true)}>Delete</DropdownItem>
      </DropdownMenu>
      <ConfirmationAlertModal
        title="Delete"
        body="Are you sure you want to delete?"
        onClose={() => setDeleteAlert(false)}
        confrimText="Yes"
        onConfrim={onDelete}
        isOpen={deleteAlert}
      />
    </UncontrolledDropdown>
  );
}
