import React, { useState } from "react";
import {
  faSort,
  faSortDown,
  faSortUp,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { NavLink } from "react-router-dom";
import { useSortBy, useTable } from "react-table";
import {
  Card,
  Container,
  Input,
  Nav,
  Navbar,
  Table,
  UncontrolledTooltip,
} from "reactstrap";
import UnControlledDropdown from "../../components/UnControlledDropdown";
import actions from "../../redux/actions";
import CreateEditSymptoms from "./CreateEditSymptoms";
import ReactPaginate from "react-paginate";
import PaginationContainer from "../../components/PaginationContainer";
import { showError } from "../../utilities/alerts/toastAlerts";
export default function Symptoms() {
  const [tableData, setTableData] = React.useState([]);
  const [modalVisible, setIsModalVisible] = React.useState(false);
  const [editData, setEditData] = React.useState({});
  const [isEdit, setisEdit] = React.useState(false);
  const [pageCount, setPageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = React.useState("");

  const getApiCall = () => {
    actions
      .getSymptoms({ pageNo: currentPage })
      .then((res) => {
        console.log(res, "bogy");
        setPageCount(Math.ceil(res.data.count / 10));
        setTableData(res.data.rows);
      })
      .catch((err) => {
        console.log(err, "the error");
      });
  };
  React.useEffect(() => {
    let timerDelay = !!searchText ? 500 : 0;
    const searchTimeout = setTimeout(() => {
      actions
        .getSymptoms({
          pageNo: currentPage,
          ...(!!searchText && {
            pageNo: 1,
            search: searchText,
          }),
        })
        .then((res) => {
          console.log(res, "bogy");
          setPageCount(Math.ceil(res.data.count / 10));
          setTableData(res.data.rows);
        })
        .catch((err) => {
          console.log(err, "the error");
        });

      return () => {
        if (searchTimeout) {
          clearTimeout(searchTimeout);
        }
      };
    }, timerDelay);
  }, [currentPage, searchText]);

  const onSearchTextChange = (e) => {
    setSearchText(e.target.value);
  };

  const data = React.useMemo(() => tableData, [tableData]);
  console.log(tableData, "the aoijsfoidjoj");
  const columns = React.useMemo(
    () => [
      {
        Header: "Symptom Name",
        accessor: "symptomsName", // accessor is the "key" in the data
      },
      {
        Header: "Symptom Code",
        accessor: "symptomsCode",
      },
      {
        Header: "Keywords",
        accessor: "keywords",
      },
    ],
    []
  );

  const openModal = () => {
    setisEdit(false);
    setIsModalVisible(true);
  };

  const toggleModal = () => {
    setIsModalVisible(false);
  };
  const onAddEditomplete = () => {
    setIsModalVisible(false);
    actions
      .getSymptoms({ pageNo: currentPage })
      .then((res) => {
        console.log(res, "bogy");
        setTableData(res.data.rows);
      })
      .catch((err) => {
        console.log(err, "the error");
      });
  };

  const _onEdit = (data) => {
    setEditData(data);
    setIsModalVisible(true);
    setisEdit(true);
  };

  const onPageClick = (event) => {
    console.log(event.selected, "the evjoaijsdofjo");
    setCurrentPage(event.selected + 1);

    const newOffset = (event.selected * 10) % 20;
    console.log(newOffset, "thejojaosjdoj ");
  };

  const _onDeleteConfirm = (data, isBlockAction) => {
    let status = "DELETE";
    if (isBlockAction) {
      status = data.status == "BLOCK" ? "UNBLOCK" : "BLOCK";
    }
    actions
      .deleteSymptom({
        status,
        id: data.id,
      })
      .then((res) => {
        getApiCall();
      })
      .catch((error) => {
        showError(error);
      });
  };

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data }, useSortBy);

  return (
    <Container fluid className="vh-100">
      <Navbar className="mt-2 mb-2" color="light">
        <Nav className="me-auto" navbar></Nav>
        <div className="row align-item-start">
          <div className="mr-4">
            <Input onChange={onSearchTextChange} placeholder="search" />
          </div>
          <div
            className="create-btn"
            style={{ textDecoration: "none" }}
            onClick={openModal}
          >
            Add Symptom
          </div>
        </div>
      </Navbar>
      <div className="mb-3" />
      <Card
        style={{ borderRadius: 10 }}
        className="shadow p-3 pt-4 mb-5 bg-white "
      >
        <Table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                <th className="th-color">Sr No.</th>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className="th-color"
                  >
                    {column.render("Header")}
                    <span className="mr-2 ml-2">
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <FontAwesomeIcon icon={faSortDown} />
                        ) : (
                          <FontAwesomeIcon icon={faSortUp} />
                        )
                      ) : (
                        <FontAwesomeIcon
                          style={{ opacity: 0.3 }}
                          icon={faSort}
                        />
                      )}
                    </span>
                  </th>
                ))}
                <th className="th-color">Action</th>
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row, index) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  <td>{(currentPage - 1) * 10 + index + 1}</td>
                  {row.cells.map((cell,j) => {
                    return (
                      <td
                        style={{
                          textOverflow: "ellipsis",
                          maxWidth: "0px",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          marginLeft: 10,
                        }}
                        id={"UncontrolledTooltipExample" + j + index}
                        className="texta"
                        {...cell.getCellProps()}
                      >
                        {cell.render("Cell")}
                        <UncontrolledTooltip
                          placement="right"
                          target={"UncontrolledTooltipExample" + j + index}
                        >
                          {cell.render("Cell")}
                        </UncontrolledTooltip>
                      </td>
                    );
                  })}
                  <td>
                    <UnControlledDropdown
                      onEdit={_onEdit}
                      onDeleteConfirm={_onDeleteConfirm}
                      data={row.original || {}}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
        <PaginationContainer onPageClick={onPageClick} pageCount={pageCount} />
      </Card>
      {modalVisible && (
        <CreateEditSymptoms
          isEdit={isEdit}
          data={editData}
          modalVisible={modalVisible}
          toggleModal={toggleModal}
          onAddEditomplete={onAddEditomplete}
        />
      )}
    </Container>
  );
}
