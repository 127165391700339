import React, { Component } from "react";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import {
  Card,
  Container,
  Input,
  Nav,
  Navbar,
  Table,
  UncontrolledTooltip,
} from "reactstrap";
import FullScreenLoader from "../../components/Loaders/FullScreenLoader";
import actions from "../../redux/actions";
// draftToHtml(convertToRaw(editorState.getCurrentContent()))
class TermConditions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editorState: EditorState.createEmpty(),
      isLoading: false,
      editId: "",
    };
  }

  componentDidMount() {
    actions
      .listHtmlContent({
        type: "2",
      })
      .then((res) => {
        console.log(res, "thjeopsaodijop");
        const html = res.data?.text;
        const contentBlock = htmlToDraft(html);
        if (contentBlock) {
          const contentState = ContentState.createFromBlockArray(
            contentBlock.contentBlocks
          );
          const editorState = EditorState.createWithContent(contentState);
          this.setState({
            editorState,
            isLoading: false,
            editId: res.data.id,
          });
        }
      });
  }

  onEditorStateChange = (editorState) => {
    console.log(editorState, "the edittor state");
    
    this.setState({
      editorState,
      id: this.state.editId,
    });
  };

  onSave = () => {
    const htmlToSend = draftToHtml(
      convertToRaw(this.state.editorState.getCurrentContent())
    );
    console.log(htmlToSend,'the html to send data');
    this.setState({ isLoading: true });
    actions
      .addEditHtmlContent({
        type: "2",
        text: htmlToSend,
        id:this.state.editId
      })
      .then((res) => {
        console.log(res, "the result values are as follow");
        this.setState({ isLoading: false });
      })
      .catch((error) => {
        this.setState({ isLoading: false });
      });
  };

  render() {
    const { editorState, isLoading } = this.state;
    return (
      <Container fluid className="vh-100">
        <Card
          style={{ borderRadius: 10, minHeight: 300 }}
          className="shadow p-3 pt-4 mb-5 bg-white"
        >
          <Editor
            editorState={editorState}
            toolbarClassName="toolbarClassName"
            wrapperClassName="wrapperClassName"
            editorClassName="editorClassName"
            onEditorStateChange={this.onEditorStateChange}
          />
          <div className="ml-auto mt-4">
            <div
              className="create-btn"
              style={{ textDecoration: "none" }}
              onClick={this.onSave}
            >
              SAVE
            </div>
          </div>
        </Card>
        <FullScreenLoader isLoading={isLoading} />
      </Container>
    );
  }
}

export default TermConditions;
