import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  FormGroup,
  Label,
  CustomInput,
  Input,
  FormFeedback,
  Card,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import actions from "../../redux/actions";
import Select from "react-select";
import ReactSelectWithCheckbox from "../../components/ReactSelectWithCheckbox";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { showError } from "../../utilities/alerts/toastAlerts";
export default function CreateRelations({
  modalVisible,
  toggleModal,
  isEdit,
  vitalParamData,
  symptomsData,
  diseaseData,
  editData,
  onAddEditomplete,
}) {
  const [errorObj, setErrorData] = useState({});
  const [vitalRange, setVitalRange] = React.useState([]);
  const [state, setState] = React.useState({
    name: editData?.name || "",
    comments: editData?.comments || "",
    searchTags: editData?.searchTags || "",
  });
  console.log(editData, "the edit data value");
  const [selectedVitalParam, setSelectedVitalParam] = useState(
    editData?.selectedVitalParam || null
  );
  const [selectedVitalRange, setSelectedVitalRange] = useState(
    editData?.selectedVitalRange || null
  );
  const [selectedSymptom, setSelecetedSymptom] = useState(
    editData?.selectedSymptom || null
  );
  const [selectedDisease, setSelectedDisease] = useState(
    editData?.selectedDisease || null
  );
  const updateState = (data) => setState((val) => ({ ...val, ...data }));

  React.useEffect(() => {
    if (!selectedVitalParam) {
      return;
    }
    actions
      .getVitalData({
        pageNo: 1,
        vitalParameterId: selectedVitalParam.value,
      })
      .then((res) => {
        const rangeData = res.data?.listRecord?.map((val) => {
          return {
            value: val.id,
            label: `${val.vitalStartRange} - ${val.vitalEndRange}`,
          };
        });
        setVitalRange(rangeData);
        setSelectedVitalRange(null);
      });
  }, [selectedVitalParam]);

  const onParamSelect = (data) => {
    setSelectedVitalParam(data);
  };

  const onVitalRangeSelect = (data) => {
    setSelectedVitalRange(data);
  };

  const onSymptomSelect = (data) => {
    setSelecetedSymptom(data);
  };
  const onDiseaseSelect = (data) => {
    setSelectedDisease(data);
  };

  const onChangeText = (e) => {
    if (errorObj[e.target.name]) {
      setErrorData((data) => ({ ...data, [e.target.name]: "" }));
    }
    updateState({ [e.target.name]: e.target.value });
  };

  const onSubmit = () => {
    let apiData = {};
    if (!state.name) {
      setErrorData({
        name: "Name is required",
      });
      return;
    }
    if (!!selectedVitalParam) {
      apiData.vitalParametereId = selectedVitalParam.value + "";
    }
    if (!!selectedVitalRange) {
      apiData.vitalRangeId = selectedVitalRange.value + "";
    }
    if (!!selectedSymptom) {
      apiData.symptomsId = selectedSymptom.value + "";
    }
    if (!!selectedDisease) {
      apiData.diseaseId = selectedDisease.value + "";
    }
    let totalValuesAvailLength = Object.keys(apiData).length;
    if (totalValuesAvailLength < 2) {
      showError({
        message:
          "Minimum two keys out of vital range,parameter, symptoms, disease should be selected",
      });
      return;
    }
    apiData.groupName = state.name;
    if (!!state.searchTags) {
      apiData.searchTags = state.searchTags;
    }
    if (!!state.comments) {
      apiData.comments = state.comments;
    }
    if(isEdit){
        apiData.id=editData.id;
    }
    console.log(apiData, "the api data oj");
    actions
      .addEditRelation(apiData)
      .then((res) => {
        console.log(res, "the response value");
        onAddEditomplete();
      })
      .catch((error) => {
        console.log(error, "the error value");
        //   setIsLoading(false);
        showError(error);
      });
  };

  return (
    <Modal size="xl" centered isOpen={modalVisible} toggle={toggleModal}>
      <ModalHeader toggle={toggleModal}>{`${
        isEdit ? "Edit" : "Add"
      } Relation`}</ModalHeader>
      <ModalBody>
        <Container fluid>
          <Row className="justify-content-between">
            <Col md="5" className="mb-3">
              <Card className="shadow p-3 pt-4 mb-5 bg-white ">
                <FormGroup>
                  <label>Name</label>
                  <Input
                    name={"name"}
                    onChange={onChangeText}
                    value={state.name}
                    invalid={!!errorObj.name}
                    type="text"
                    placeholder="Enter name"
                  />
                  <FormFeedback>{errorObj.name}</FormFeedback>
                </FormGroup>
                <FormGroup>
                  <label>
                    Search Tags
                    <small>(Mulitiple value {`'comma'`} seprated)</small>
                  </label>
                  <Input
                    name={"searchTags"}
                    onChange={onChangeText}
                    value={state.searchTags}
                    invalid={!!errorObj.name}
                    type="text"
                    placeholder="Enter searchTags"
                  />
                  <FormFeedback>{errorObj.searchTags}</FormFeedback>
                </FormGroup>
                <FormGroup>
                  <label>Vital Parameter</label>
                  <Select
                    value={selectedVitalParam}
                    onChange={onParamSelect}
                    options={vitalParamData}
                    isClearable
                  />
                </FormGroup>
                <FormGroup>
                  <label>
                    Vital Range <small>(Start Range - End Range)</small>
                  </label>
                  <Select
                    value={selectedVitalRange}
                    onChange={onVitalRangeSelect}
                    options={vitalRange}
                    isClearable
                  />
                </FormGroup>
                <FormGroup>
                  <label>Symptom</label>
                  <Select
                    value={selectedSymptom}
                    onChange={onSymptomSelect}
                    options={symptomsData}
                    isClearable
                  />
                </FormGroup>
                <FormGroup>
                  <label>Disease</label>
                  <Select
                    value={selectedDisease}
                    onChange={onDiseaseSelect}
                    options={diseaseData}
                    isClearable
                  />
                </FormGroup>
                <FormGroup>
                  <label>Comment</label>
                  <Input
                    name={"comments"}
                    onChange={onChangeText}
                    value={state.comments}
                    invalid={!!errorObj.name}
                    type="text"
                    placeholder="Enter comments"
                  />
                  <FormFeedback>{errorObj.comments}</FormFeedback>
                </FormGroup>
                <div
                  className="create-btn"
                  style={{
                    textDecoration: "none",
                    width: 100,
                    textAlign: "center",
                  }}
                  onClick={onSubmit}
                >
                  Submit
                </div>
              </Card>
            </Col>
            <Col className="my-auto" md="1">
              <FontAwesomeIcon size="5x" icon={faArrowRight} className="mr-2" />
            </Col>
            <Col md="4" className="mb-3 my-auto">
              <Card className="shadow p-3 pt-4 mb-5 bg-white ">
                <InlineList label="Name" value={state.name} />
                <InlineList label="Search Tags" value={state.searchTags} />
                <InlineList
                  label="Vital Param"
                  value={selectedVitalParam?.label}
                />

                <InlineList
                  label="Vital Range"
                  value={selectedVitalRange?.label}
                />

                <InlineList label="Symptom" value={selectedSymptom?.label} />

                <InlineList label="Disease" value={selectedDisease?.label} />
                <InlineList label="Comment" value={state.comments} />
              </Card>
            </Col>
          </Row>
        </Container>
      </ModalBody>
    </Modal>
  );
}

const InlineList = ({ label, value }) => {
  return (
    <div
      className="inline-list-border"
      style={{ display: "inline-block", overflow: "hidden" }}
    >
      <label style={{ width: 100 }}>{label}:</label>
      <span style={{ overflow: "hidden" }}>{value || "-"}</span>
    </div>
  );
};
