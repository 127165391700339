import React from "react";
import { Button, Card, Container, Navbar, Table } from "reactstrap";
import PaginationContainer from "../../components/PaginationContainer";
import { useSortBy, useTable } from "react-table";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleCheck,
  faCircleXmark,
  faSort,
  faSortDown,
  faSortUp,
} from "@fortawesome/free-solid-svg-icons";
import actions from "../../redux/actions";
import { Col, Row, FormGroup, FormFeedback } from "reactstrap";
import Select from "react-select";
import AddWeatherFactor from "./AddWeatherFactor";

export default function WeatherFactorView() {
  const [tableData, setTableData] = React.useState([]);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [pageCount, setPageCount] = React.useState(1);
  const [countries, setCountries] = React.useState([]);
  const [states, setStates] = React.useState([]);
  const [modalVisible, setIsModalVisible] = React.useState(false);
  const [editData, setEditData] = React.useState({});
  const [rowIndex, setRowIndex] = React.useState();

  const monthDataArray = [
    {
      Header: "January",
      accessor: "january",
    },
    { Header: "February", accessor: "february" },
    { Header: "March", accessor: "march" },
    { Header: "April", accessor: "april" },
    { Header: "May", accessor: "may" },
    { Header: "June", accessor: "june" },
    { Header: "July", accessor: "july" },
    { Header: "August", accessor: "august" },
    { Header: "September", accessor: "september" },
    { Header: "October", accessor: "october" },
    { Header: "November", accessor: "november" },
    { Header: "December", accessor: "december" },
  ];

  const columns = React.useMemo(
    () => [
      {
        Header: "City",
        accessor: "cityName",
      },

      ...monthDataArray.map((e) => {
        return {
          Header: e.Header,
          accessor: e.accessor,
          Cell: ({ value }) => {
            return value == "true" ? (
              <FontAwesomeIcon
                icon={faCircleCheck}
                style={{ color: "#2aa232" }}
              />
            ) : (
              <FontAwesomeIcon
                icon={faCircleXmark}
                style={{ color: "#e10e4e" }}
              />
            );
          },
        };
      }),
    ],
    []
  );
  const data = React.useMemo(() => tableData, [tableData]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data }, useSortBy);

  const getWeatherFactor = (id) => {
    actions
      .getWeatherFactor({ stateId: id })
      .then((res) => {
        setTableData(res.data);
      })
      .catch((e) => {});
  };

  const onAddEditComplete = (index, month) => {
    let mapData = tableData;
    mapData[index][month] = true;
    setTableData(mapData);
  };

  const get_country = () => {
    actions
      .getCountries()
      .then((res) => {
        setCountries(
          res.data.map((e) => {
            return {
              label: e.name,
              value: e.id,
            };
          })
        );
      })
      .catch((e) => {});
  };

  const get_states = (id) => {
    actions
      .getStates({
        country_id: id,
      })
      .then((res) => {
        console.log(res);
        setStates(
          res.data.map((e) => {
            return {
              label: e.name,
              value: e.id,
            };
          })
        );
      })
      .catch((e) => {});
  };

  React.useEffect(() => {
    get_country();
  }, []);

  const toggleModal = () => {
    setIsModalVisible(false);
  };
  return (
    <Container>
      <Row>
        <Col lg="6">
          <FormGroup>
            <label>Select County</label>
            <Select
              onChange={(data) => {
                get_states(data.value);
              }}
              options={countries}
              isClearable
            />
            <FormFeedback>Error</FormFeedback>
          </FormGroup>
        </Col>
        <Col lg="6">
          <FormGroup>
            <label>Select State</label>
            <Select
              onChange={(data) => {
                getWeatherFactor(data.value);
              }}
              options={states}
              isClearable
            />
            <FormFeedback>Error</FormFeedback>
          </FormGroup>
        </Col>
      </Row>
      <Navbar className="mt-2 mb-2" color="light">
        {/* <div className="row align-item-start">
          <div className="mr-4">
          <Input onChange={onSearchTextChange} placeholder="search" />
        </div>
      
        </div> */}
      </Navbar>
      <div className="mb-3" />
      <Card
        style={{ borderRadius: 10 }}
        className="shadow p-3 pt-4 mb-5 bg-white "
      >
        <Table className="table-scroll-on" {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup, i) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                <th className="th-color">Sr. No</th>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className="th-color"
                  >
                    {column.render("Header")}
                    <span className="mr-2 ml-2">
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <FontAwesomeIcon icon={faSortDown} />
                        ) : (
                          <FontAwesomeIcon icon={faSortUp} />
                        )
                      ) : (
                        <FontAwesomeIcon
                          style={{ opacity: 0.3 }}
                          icon={faSort}
                        />
                      )}
                    </span>
                  </th>
                ))}
                <th className="th-color">Action</th>
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <tr
                  className={`${row.original.status == "BLOCK" ? "block" : ""}`}
                  {...row.getRowProps()}
                >
                  <td>{(currentPage - 1) * 10 + i + 1}</td>
                  {row.cells.map((cell) => {
                    return (
                      <td className="texta" {...cell.getCellProps()}>
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                  <Button
                    className="mt-1"
                    onClick={() => {
                      setRowIndex(i);
                      setEditData(row.original);
                      setIsModalVisible(true);
                    }}
                  >
                    Update
                  </Button>
                </tr>
              );
            })}
          </tbody>
        </Table>
        <PaginationContainer onPageClick={() => {}} pageCount={pageCount} />
      </Card>
      {modalVisible && (
        <AddWeatherFactor
          modalVisible={modalVisible}
          toggleModal={toggleModal}
          editData={editData}
          onAddEditComplete={onAddEditComplete}
          rowIndex={rowIndex}
        />
      )}
    </Container>
  );
}
