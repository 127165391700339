import React, { useState } from "react";
import {
  Container,
  Navbar,
  NavbarBrand,
  Nav,
  NavItem,
  Card,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  FormFeedback,
  Input,
} from "reactstrap";
import { useTable, useSortBy } from "react-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faBriefcase,
  faPaperPlane,
  faQuestion,
  faImage,
  faCopy,
  faUpDown,
  faduioton,
  faSort,
  faSortUp,
  faSortDown,
} from "@fortawesome/free-solid-svg-icons";
import { useLocation } from "react-router-dom";
import actions from "../../redux/actions";
import UnControlledDropdown from "../../components/UnControlledDropdown";
import validateInput from "../../utilities/validations/emptyValidator";
import { showError } from "../../utilities/alerts/toastAlerts";
const getQuestionType = (path) => {
  switch (path) {
    case "/question/physical":
      return 1;
    case "/question/social":
      return 2;
    case "/question/surgical":
      return 3;
    case "/question/userHistory":
      return 4;
    case "/question/tabacoo":
      return 5;
    case "/question/alcohal":
      return 6;
  }
};

export default function Options(props) {
  const location = useLocation();
  console.log(location, "the location data value");
  const [tableData, setTableData] = React.useState([]);
  const [modalVisible, setIsModalVisible] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const { optionData = {} } = location?.state || {};
  const [state, setState] = React.useState({
    optionString: "",
    value: "",
    sequenceNo: "",
    keywords: "",
    valueType: "",
  });
  const [editOptionId, setEditOptionId] = React.useState(false);
  const [isEdit, setisEdit] = React.useState(false);

  React.useEffect(() => {
    getApiCall();
  }, []);

  const getApiCall = () => {
    const { data } = location?.state;
    const questionId = data.id;
    console.log(questionId, "the question id value is as follow");
    actions
      .lisiOptionsForQueston({
        questionId,
        pageNo: 1,
      })
      .then((res) => {
        setTableData(res.data || []);
        console.log(res, "the result data value");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const data = React.useMemo(() => tableData, [tableData]);
  const [errorObj, setErrorData] = useState({});
  const updateState = (data) => setState((state) => ({ ...state, ...data }));
  const columns = React.useMemo(
    () => [
      {
        Header: "Option",
        accessor: "optionString", // accessor is the "key" in the data
      },
      {
        Header: "Score Addition",
        accessor: "value",
      },
      {
        Header: "Value  Type",
        accessor: "valueType",
      },
      {
        Header: "Display No",
        accessor: "sequenceNo",
      },
      {
        Header: "Keywords",
        accessor: "keywords",
      },
    ],
    []
  );

  const toggleModal = () => {
    setisEdit(false);
    setIsModalVisible(false);
  };

  const openOptionModal = () => {
    updateState({
      optionString: "",
      value: "",
      sequenceNo: "",
      keywords: "",
      valueType: "",
    });
    setisEdit(false);
    setIsModalVisible(true);
  };

  const onChangeText = (e) => {
    if (errorObj[e.target.name]) {
      setErrorData((data) => ({ ...data, [e.target.name]: "" }));
    }
    updateState({ [e.target.name]: e.target.value });
  };

  const onOptionSubmit = () => {
    const { isValid, errors } = validateInput({
      optionString: { value: state.optionString, name: "Option Text" },
      sequenceNo: { value: state.sequenceNo, name: "Display No" },
      value: { value: state.value, name: "Score Addition" },
      valueType: { value: state.valueType, name: "Value Type" },
    });

    console.log(errors, "the errors data value");
    if (!isValid) {
      setErrorData(errors);
      return;
    } else {
      console.log(location, "the locaitojo");
      const { data } = location?.state;
      const questionId = data.id;
      let apiData = {
        ...state,
        questionId: questionId + "",
      };
      if (isEdit) {
        apiData.id = editOptionId;
      }
      if (!!state.keywords) {
        apiData.keywords = state.keywords;
      }

      actions
        .addEditOptionForQueston(apiData)
        .then((res) => {
          getApiCall();
          console.log(res, "the resule vaue");
          setIsLoading(false);
          setIsModalVisible(false);
        })
        .catch((error) => {
          console.log(error, "the error value");
          setIsLoading(false);
          showError(error);
        });
    }
  };

  const _onEdit = (optionData) => {
    setisEdit(true);
    updateState({
      optionString: optionData?.optionString || "",
      value: optionData?.value || "",
      sequenceNo: optionData?.sequenceNo || "",
    });
    setEditOptionId(optionData.id);
    setIsModalVisible(true);
  };

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data }, useSortBy);

  return (
    <Container fluid className="vh-100">
      <Navbar className="mt-2 mb-2" color="light">
        <Nav className="me-auto" navbar></Nav>

        <div
          className="create-btn"
          style={{ textDecoration: "none" }}
          onClick={openOptionModal}
        >
          Create New Option
        </div>
      </Navbar>
      <div className="mb-3" />
      <Card
        style={{ borderRadius: 10 }}
        className="shadow p-3 pt-4 mb-5 bg-white "
      >
        <Table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup, i) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                <th className="th-color">Sr. No</th>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className="th-color"
                  >
                    {column.render("Header")}
                    <span className="mr-2 ml-2">
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <FontAwesomeIcon icon={faSortDown} />
                        ) : (
                          <FontAwesomeIcon icon={faSortUp} />
                        )
                      ) : (
                        <FontAwesomeIcon
                          style={{ opacity: 0.3 }}
                          icon={faSort}
                        />
                      )}
                    </span>
                  </th>
                ))}
                <th className="th-color">Action</th>
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  <td className="texta">{i + 1}</td>
                  {row.cells.map((cell) => {
                    return (
                      <td className="texta" {...cell.getCellProps()}>
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                  <td>
                    <UnControlledDropdown
                      onEdit={_onEdit}
                      data={row.original || {}}
                      type={"PROFILE_OPTIONS"}
                      onDeleteComplete={getApiCall}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Card>
      {modalVisible && (
        <Modal centered isOpen={modalVisible} toggle={toggleModal}>
          <ModalHeader toggle={toggleModal}>
            {isEdit ? "Edit Option" : "Add Option"}
          </ModalHeader>
          <ModalBody>
            <FormGroup>
              <label>Option Text</label>
              <Input
                name={"optionString"}
                onChange={onChangeText}
                value={state.optionString}
                invalid={!!errorObj.optionString}
                type="text"
              />
              <FormFeedback>{errorObj.optionString}</FormFeedback>
            </FormGroup>
            <FormGroup>
              <label>Score Addition</label>
              <Input
                name={"value"}
                onChange={onChangeText}
                value={state.value}
                invalid={!!errorObj.value}
                type="number"
              />
              <FormFeedback>{errorObj.value}</FormFeedback>
            </FormGroup>
            <FormGroup>
              <label>Value Type</label>
              <div>
                <div className="form-check form-check-inline">
                  <Input
                    className="form-check-input"
                    type="radio"
                    name="textInputb"
                    id="flexRadioDefault11"
                    checked={state.valueType == "PERCENTAGE"}
                    onClick={() => {
                      updateState({ valueType: "PERCENTAGE" });
                      setErrorData((data) => ({ ...data, valueType: "" }));
                    }}
                  />
                  <label className="form-check-label" for="flexRadioDefault1">
                    PERCENTAGE
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <Input
                    className="form-check-input"
                    type="radio"
                    name="flexRadioDefaultb"
                    id="flexRadioDefault21"
                    checked={state.valueType == "SCORE"}
                    onClick={() => {
                      updateState({ valueType: "SCORE" });
                      setErrorData((data) => ({ ...data, valueType: "" }));
                    }}
                  />
                  <label className="form-check-label" for="flexRadioDefault2">
                    SCORE
                  </label>
                </div>
              </div>
              <p
                className={`invalid-feedback ${
                  !!errorObj.valueType ? "error-text" : ""
                }`}
                valid={false}
              >
                {errorObj.valueType}
              </p>
            </FormGroup>
            <FormGroup>
              <label>Keywords</label>
              <Input
                name={"keywords"}
                onChange={onChangeText}
                value={state.keywords}
                invalid={!!errorObj.keywords}
                type="text"
              />
              <FormFeedback>{errorObj.keywords}</FormFeedback>
            </FormGroup>
            <FormGroup>
              <label>Display No</label>
              <Input
                name={"sequenceNo"}
                onChange={onChangeText}
                value={state.sequenceNo}
                invalid={!!errorObj.sequenceNo}
                type="number"
              />
              <FormFeedback>{errorObj.sequenceNo}</FormFeedback>
            </FormGroup>

            <div
              className="create-btn"
              style={{
                textDecoration: "none",
                width: 100,
                textAlign: "center",
              }}
              onClick={onOptionSubmit}
            >
              Submit
            </div>
          </ModalBody>
        </Modal>
      )}
    </Container>
  );
}
