import { apiPost, apiGet, apiReq, apiPut, randomString } from "../utils";
import qs from "query-string";
import { formTypes, authApiUrl, apiUrl } from "../constants";
import * as urls from "../config/urls";

const headers = {
  //   "Content-Type": formTypes.urlencoded,
  //   "X-Parse-Revocable-Session": 1,
  //   "X-Parse-Application-Id": formTypes.appId,
  //   'Access-Control-Allow-Origin' : '*',
  //   'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
};

export function addEditRelationAPI(data) {
  return apiPost(urls.CREATE_EDIT_RELATION, data);
}

export function listRelationsDataAPI(data) {
  return apiPost(urls.LIST_RELATION, data);
}

export function deleteBlockUnBlockAPI(data) {
  return apiPost(urls.DELETE_BLOCK_UNBLOCK, data);
}

export function deleteBlokcRelationAPI(data = {}) {
  return apiPost(urls.DELETE_BLOCK_RELATION, data);
}

export function listReviewsAPI(data = {}) {
  return apiPost(urls.LIST_REVIEWS, data);
}

export function addEditHtmlContentAPI(data = {}) {
  return apiPost(urls.ADD_EDIT_CONTENT, data);
}

export function listHtmlContentAPI(data = {}) {
  return apiPost(urls.LIST_HTML_CONTENT, data);
}

export function listCustomerAPI(data = {}) {
  return apiPost(urls.LIST_CUSTOMERS, data);
}
export function addEditCommunityAPI(data = {}) {
  return apiPost(urls.ADD_EDIT_COMMUNITY, data);
}

export function listCommunityAPI(data = {}) {
  return apiPost(urls.LIST_COMMUNITY, data);
}

export function triggerNotificationControlsApi(data = {}) {
  return apiPost(urls.CREATE_NOTIFICATION_CONTROLS, data);
}

export function getCustomersAllLogsApi() {
  return apiGet(urls.GET_ALL_CUSTOMERS_LOGS);
}

export function getCustomerWiseLogsApi(data = {}) {
  return apiPost(urls.GET_ALL_CUSTOMERS_LOGS, data);
}

export function getCallbackRequestApi() {
  return apiGet(urls.CUSTOMER_CALLBACK_REQUEST);
}

export function getWeatherFactorApi(data = {}) {
  return apiPost(urls.GET_WEATHER_FACTOR_LIST, data);
}

export function getCountriesApi() {
  return apiGet(urls.GET_COUNTIES);
}

export function getStateApi(data = {}) {
  return apiPost(urls.GET_STATES, data);
}

export function createWeatherFactorApi(data = {}) {
  return apiPost(urls.CREATE_WEATHER_FACTOR, data);
}
